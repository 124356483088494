<template>
  <div class="view-container h-100">
    <NavOrderDetails
      :order="order"
      :on-close="closeOrderDetails"
      :edit-button-active="orderIsEditable"
      :edit-order="editOrder"
      :is-loading="isLoading"
    />
    <div class="p-2 d-flex d-md-none">
      <b-button
        id="set-active-mobile-view-cart-btn"
        class="w-100"
        :variant="activeMobileView === 'cart' ? 'primary' : 'outline-primary'"
        @click="setActiveMobileView('cart')"
        >{{ $t('message.orderDetails.orderContent') }}</b-button
      >
      <b-button
        id="set-active-mobile-view-customer-btn"
        class="w-100"
        :variant="activeMobileView === 'customerDetails' ? 'primary' : 'outline-primary'"
        @click="setActiveMobileView('customerDetails')"
        >{{ $t('message.orderDetails.customerData') }}</b-button
      >
    </div>
    <div class="order-details-container">
      <Loader v-if="isLoading" />
      <div v-if="showCart" class="widget">        
        <div class="widget-body-wrapper pt-0">
          <b-row>
            <b-col cols="12">
              <Cart
                :full="true"
                :from-order-details-modal="fromModal"
                :order-details="true"
                :cart="cart"
                :order-source="order.menu_source"
                :order="order"
                :order-status="order.status"
                :order-brand-id="order.brand_id"
                :order-bills="bills"
                :order-all-bills-length="allBillsLength"
                :disabled-mapping="isOrderNew"
                @closeModal="$emit('closeModal')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="orderComment" cols="12" class="mt-3">
              <b-alert show variant="warning">
                <div class="font-weight-medium h5">{{ $t('message.cart.comment') }}:</div>
                <span style="white-space: pre">{{ orderComment }}</span>
              </b-alert>
            </b-col>
          </b-row>
        </div>
        <div class="widget-footer-container">
          <b-col cols="12" class="widget-footer">
            <b-row v-if="pyszneTwoPhase" no-gutters class="mb-2">
              <b-col>
                <b-alert show class="h4 message-centred bg-warning m-0 text-dark">{{
                  $t('message.orderDetails.orderNotAccepted')
                }}</b-alert>
              </b-col>
            </b-row>
            <b-row v-else class="order-details-footer-buttons mb-2">
              <b-col>
                <b-button
                  v-if="finishOrderButtonIsActive"
                  id="show-finish-order-modal-btn"
                  block
                  size="lg"
                  :disabled="finishOrderDisabled"
                  :variant="order.status !== 'finished' ? 'success' : 'primary'"
                  class="h-100 action"
                  @click="showFinishOrderModal"
                >
                  <i class="fas fa-clipboard-check action__icon" />
                  <span class="action__text">
                    {{
                      order.status !== 'finished'
                        ? $t('message.orderDetails.finishOrderButton')
                        : $t('message.orderDetails.changeFinishOrderButton')
                    }}
                  </span>
                </b-button>
                <b-button v-else block size="lg" variant="secondary" class="h-100 action" disabled>
                  <i class="fas fa-clipboard-check action__icon" />
                  <span class="action__text">{{ $t('message.orderDetails.finishOrderButton') }}</span>
                </b-button>
              </b-col>

              <b-col v-if="showPrintOrderConfirmationBtn">
                <b-button
                  block
                  size="lg"
                  :variant="printOrderConfirmationButtonIsActive ? 'info' : 'secondary'"
                  class="h-100 action"
                  :disabled="!printOrderConfirmationButtonIsActive"
                  @click="printOrderConfirmation()"
                >
                  <i class="fas fa-print action__icon" />
                  <span class="action__text">{{ $t('message.orderDetails.printOrderConfirmation.btn') }}</span>
                </b-button>
              </b-col>
              <b-col v-if="showPrintBillBtn">
                <b-button
                  :variant="'info'"
                  size="lg"
                  block
                  class="h-100 action"
                  :disabled="billPrintDisabled"
                  @click="printBillMethod()"
                >
                  <div
                    v-if="showCloseOrderOnPrintBillButtonCheckbox"
                    id="closeOrderOnPrintButtonCheckbox"
                    class="d-inline"
                    @click.stop
                  >
                    <b-form-checkbox
                      v-model="closeOrderOnPrintBillButton"
                      inline
                      size="lg"
                      class="m-0 p-0 center-checkbox action__checkbox"
                      :value="true"
                      :unchecked-value="false"
                    />
                  </div>
                  <b-tooltip target="closeOrderOnPrintButtonCheckbox" triggers="hover" variant="info">
                    {{ $t('message.orderDetails.closeOrderOnPrintBill') }}
                  </b-tooltip>
                  <i class="fas fa-receipt action__icon" />
                  <span class="action__text">{{ $t('message.orderDetails.printBill') }}</span>
                  <!-- this div is necessary for tooltip to show when opening page -->
                  <div v-if="order.printing_error" class="d-inline">
                    <TooltipButton
                      id="printingErrorButton"
                      variant="info"
                      show
                      tooltip-variant="danger"
                      class="printing-error-button"
                    >
                      <i class="fas fa-question h5 text-danger"></i>
                      <template #tooltipText>
                        {{ $t('message.orderDetails.printingError') }}
                        <div>{{ order.printing_error }}</div>
                        <div>
                          <span>{{ $t('message.orderDetails.printingErrorTryAgain') }}</span>
                        </div>
                      </template>
                    </TooltipButton>
                  </div>
                </b-button>
              </b-col>
              <b-col v-if="$hasModuleAccess('invoice-print') && $store.getters['auth/getCompany'].country !== 'DE'">
                <b-button
                  v-b-modal.printInvoice
                  block
                  size="lg"
                  class="h-100 action"
                  :variant="invoicePrinted ? 'warning' : 'info'"
                  :disabled="hasSplitBills"
                >
                  <i class="fas fa-file-invoice-dollar action__icon" />
                  <span class="action__text">{{ $t('message.orderDetails.printInvoice') }}</span>
                </b-button>
              </b-col>
              <b-col
                v-if="
                  hasUserRole([ROLE.OWNER, ROLE.MANAGER]) &&
                  order.status === ORDER_STATUSES.ORDER_STATUS_FINISHED &&
                  $hasModuleAccess('restore-order')
                "
              >
                <b-button block size="lg" variant="warning" class="h-100 action" @click="restoreOrder">
                  <i class="fa fa-undo action__icon"></i>
                  <span class="action__text">{{ $t('message.orderDetails.restoreOrder') }}</span>
                </b-button>
              </b-col>
              <b-col v-if="$hasModuleAccess('orders-split-bill')">
                <b-overlay
                  :show="gettingBills"
                  rounded="sm"
                  variant="info"
                  spinner-variant="light"
                  opacity="0.6"
                  class="h-100"
                >
                  <b-button
                    block
                    size="lg"
                    class="h-100 action"
                    variant="info"
                    :disabled="
                      (order.status === 'finished' && !hasSplitBills) ||
                      (!hasSplitBills && !hasBaseBill) ||
                      !!order.invoice_id
                    "
                    :to="{ name: 'orders-split-bill', params: { id, orderStatus: order.status } }"
                    @click="$emit('closeModal')"
                  >
                    <i class="fas fa-divide action__icon" />
                    <span class="action__text">{{ $t('message.splitBill.split') }}</span>
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
            <b-row v-if="isTransferred">
              <b-col>
                <h4 class="alert alert-primary">
                  {{ $t('message.orderDetails.transferedFrom') }} {{ order.old_localization.name }}
                </h4>
              </b-col>
            </b-row>
            <template v-if="isOrderNew">
              <div class="divider divider--dashed" />
              <div class="d-flex align-items-center mb-2">
                <h4 class="d-inline mb-0">
                  {{
                    isPromisedTimePickTimeChangeAvailable
                      ? $t('message.orderDetails.changeOrAcceptPromisedTime')
                      : $t('message.orderDetails.selectPromisedTime')
                  }}
                </h4>
                <span
                  v-if="getPredictionsRequest && $hasModuleAccess('show-promised-time-prediction')"
                  class="text-info ml-2"
                >
                  <b-spinner small></b-spinner>
                  {{ $t('message.promisedTime.predictingPromisedTime') }}
                </span>
              </div>
              <b-row class="mt-1">
                <b-col
                  v-if="showPromisedTimeComponent"
                  cols="12"
                  :lg="isPromisedTimePickTimeChangeAvailable ? 12 : 10"
                  class="mb-3"
                >
                  <PromisedTime
                    :order-details="true"
                    :custom-promised-time="order.promised_time"
                    :pick-time="isPromisedTimePickTimeChangeAvailable"
                    :estimated-delivery-time="estimatedDeliveryTime"
                    @input="changePromisedTime"
                  />
                </b-col>
                <b-col v-if="order.promised_time" cols="6" >
                  <b-button
                    block
                    size="lg"
                    class="accept-btn"
                    :variant="accept ?  'success' : 'secondary' "
                    @click="
                      accept = !accept
                      decline = false
                    "
                    >
                    <span class="d-md-flex justify-content-center align-items-center">
                      <span class="d-flex justify-content-center align-items-center">
                        <i :class="`fas fa-${accept ? 'check-square' : 'square'}`" />
                        <span>{{ $t('message.orderDetails.accept') }}</span>                    
                      </span>
                      <span class="d-flex justify-content-center align-items-center">
                        <span class="accept-btn__promised-time">{{ order.promised_time }}</span>
                        <TooltipButton id="acceptOrder" class="float-right" button-class="defaultTooltipButton"
                          >?
                          <template #tooltipText>
                            {{ $t('message.orderDetails.acceptInfo') }}
                          </template>
                        </TooltipButton>
                      </span>
                    </span>
                  </b-button>
                </b-col>
                <b-col v-if="cancelOrderButtonVisible" cols="6" :offset="order.promised_time ? '' : 6" >
                  <b-button
                    block
                    size="lg"
                    class="decline-btn h-100"
                    :variant="decline ? 'danger': 'secondary' "
                    @click="
                      decline = !decline
                      accept = false
                    "
                  >
                  <i :class="`fas fa-${decline ? 'check-square' : 'square'}`" />
                    <span>{{ $t('message.orderDetails.decline') }}</span>
                    <TooltipButton
                      v-if="isTransferred"
                      id="rejectTransferredOrder"
                      class="float-right"
                      button-class="defaultTooltipButton"
                      >?
                      <template #tooltipText>
                        {{
                          $t('message.orderDetails.declineTransferInfo', {
                            oldLocalizationName: order.old_localization.name,
                          })
                        }}
                      </template>
                    </TooltipButton>
                  </b-button>
                </b-col>
                <div class="mt-3">
                  <b-form-group v-if="deliveryTypeChosen === null" :label="$t('message.orderFormFull.deliveryType')">
                    <b-radio-group>
                      <b-radio
                        v-for="type in deliveryTypes"
                        :key="type.name"
                        @change="deliveryTypeChosen = type.value"
                        >{{ $t(type.name) }}</b-radio
                      >
                    </b-radio-group>
                  </b-form-group>
                </div>
              </b-row>
              <div class="divider divider--dashed" />
            </template>
            <b-row>
              <b-col cols="12">
                <b-alert :show="responseErrors.hasOwnProperty('promised_time')" variant="danger">
                  <response-error-msg field="promised_time" :response-errors="responseErrors" />
                </b-alert>
                <b-alert :show="responseErrors.hasOwnProperty('non_field_errors')" variant="danger">
                  <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
                </b-alert>
              </b-col>
              <b-col cols="12">
                <b-button
                  id="okButton"
                  ref="okButton"
                  v-shortkey="['esc']"
                  class="action action--v-center action--ok"
                  block
                  size="lg"
                  variant="secondary"
                  :disabled="acceptOrDeclineDisabled"
                  @shortkey="acceptOrDecline()"
                  @click.prevent="acceptOrDecline()"
                >
                  <kbd class="pb-0 mr-2 bg-white text-dark action__shortcut">ESC</kbd>
                  <span class="action__text action__text--lg action__text--inline">
                    {{ isOrderNew ? $t('message.common.confirm') : 'OK'}} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </div>
      <CustomerDetails
        v-if="showCustomerDetails"
        :order="order"
        :payment-methods="paymentMethods"
        :is-order-loading="isLoading"
      />
    </div>
    <finish-order-modal
      v-if="orderRequestStatus === 'success'"
      :payment-methods="paymentMethods"
      :change-finished-order-status="_changeFinishedOrderStatus"
      @close="closeFinishOrderModal"
    />
    <print-invoice-modal />
    <change-order-localization-modal :order="order" @transferred="$emit('closeModal')" />
    <order-edit-history-modal :history="order.logs" />
  </div>
</template>

<script>
/* global $ */
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import {
  PICKUP_TYPE_ON_SITE,
  PICKUP_TYPE_TAKOUT,
  PICKUP_TYPE_DELIVERY,
  MENU_SOURCE_CHOICES,
  MENU_SOURCE_COLORS,
  ORDER_STATUSES,
  POS_API_DATETIME_INPUT_FORMAT,
  ROLE,
} from '@/common/constants'
import { API } from '@/services/api/api'
import { printBillMixins, orderMixins, responseErrorsHandler, menuSourcesMixins } from '@/mixins'
import Cart from '@/components/cart/Cart'
import Loader from '@/components/Loader'
import PromisedTime from '@/components/PromisedTime'
import TooltipButton from '@/components/TooltipButton'
import NavOrderDetails from '@/components/navigation/NavOrderDetails'
import CustomerDetails from './CustomerDetails'
import FinishOrderModal from './FinishOrderModal'
import PrintInvoiceModal from './PrintInvoiceModal'
import ChangeOrderLocalizationModal from './ChangeOrderLocalizationModal'
import OrderEditHistoryModal from '@/components/OrderEditHistoryModal'
import store from '../_store'

const STORE = '_orderDetails'

export default {
  name: 'OrderDetailsContent',
  components: {
    TooltipButton,
    PrintInvoiceModal,
    Cart,
    FinishOrderModal,
    PromisedTime,
    ChangeOrderLocalizationModal,
    NavOrderDetails,
    Loader,
    CustomerDetails,
    OrderEditHistoryModal,
  },
  mixins: [printBillMixins, orderMixins, responseErrorsHandler, menuSourcesMixins],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    fromModal: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      MENU_SOURCE_CHOICES,
      MENU_SOURCE_COLORS,
      ORDER_STATUSES,
      PICKUP_TYPE_ON_SITE,
      selectedFinishOrderChoice: '',
      finishOrderStatusSet: false,
      paymentMethods: [
        { text: this.$t('message.orderFormFull.paymentTypes.cash'), value: 'cash', icon: 'fa-money-bill-wave' },
        { text: this.$t('message.orderFormFull.paymentTypes.paid'), value: 'paid', icon: 'fa-clipboard-check' },
        { text: this.$t('message.orderFormFull.paymentTypes.card'), value: 'card', icon: 'fa-credit-card' },
      ],
      deliveryTypes: [
        { name: this.$t('message.orderFormFull.deliveryTypes.asap'), value: 'asap' },
        { name: this.$t('message.orderFormFull.deliveryTypes.onTime'), value: 'ontime' },
      ],
      promisedTimeValue: '',
      accept: false,
      decline: false,
      orderConfirmationIsPrinting: false,
      deliveryTypeChosen: '',
      closeOrderOnPrintBillButton: JSON.parse(sessionStorage.getItem('localizationPreferences'))
        ? JSON.parse(sessionStorage.getItem('localizationPreferences')).close_order_on_print_bill_button
        : false,
      gettingBills: false,
      activeMobileView: 'cart',
      ROLE,
    }
  },
  computed: {
    ...mapState({
      orderId: (state) => state[STORE].orderId,
      baseBillId: (state) => state[STORE].baseBill?.id || null,
      baseBillPostions: (state) => state[STORE].baseBill?.positions || [],
      bills: (state) => state[STORE].bills || [],
    }),
    ...mapGetters(STORE, [
      'order',
      'cart',
      'orderRequestStatus',
      'hasBaseBill',
      'hasSplitBills',
      'allBillsLength',
      'orderIsEditable',
    ]),
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      companyLocalizations: 'auth/companyLocalizations',
      isMobile: 'isMobile',
      menus: 'menus',
      menu: 'menu',
      getPredictionsRequest: 'getPredictionsRequest',
    }),
    ...mapFields('_orderDetails', {
      estimatedDeliveryTime: 'estimatedDeliveryTime',
    }),
    isLoading() {
      return this.orderRequestStatus === 'loading'
    },
    showCart() {
      return !this.isMobile || this.activeMobileView === 'cart'
    },
    showCustomerDetails() {
      return !this.isMobile || this.activeMobileView === 'customerDetails'
    },
    showPrintOrderConfirmationBtn() {
      // Hard block for brand id 3678 - Jak w domu bistro
      return this.$hasModuleAccess('receipt-print') && this.order.brand_id !== 3678
    },
    showPrintBillBtn() {
      return this.hasFiscal && this.$hasModuleAccess('bill-print') && this.order.brand_id !== 3678
    },
    customer() {
      return this.order.customer || {}
    },
    orderComment() {
      let orderComment = this.cart.comment
      const meals = this.cart.meals
      if (meals) {
        // has to check if meals exists, otherwise throws warnings
        meals.forEach(function (meal) {
          if (meal.comment) orderComment = `${orderComment}\n${meal.name}: ${meal.comment}`
        })
        return orderComment
      }
      return ''
    },
    finished_type() {
      if (this.closeOrderOnPrintBillButton) {
        return ORDER_STATUSES.ORDER_STATUS_FINISHED
      }
    },
    showCloseOrderOnPrintBillButtonCheckbox() {
      return (
        (this.order.pickup_type === PICKUP_TYPE_ON_SITE || this.order.pickup_type === PICKUP_TYPE_TAKOUT) &&
        !this.order.finished_type
      )
    },
    finishOrderChoices() {
      const choices = [
        {
          name: this.$t('message.orderDetails.finishOrderChoices.canceled'),
          value: 'canceled',
          pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.delivered'),
          value: 'delivered',
          pickupTypes: [PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.notDelivered'),
          value: 'not delivered',
          pickupTypes: [PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.pickedUp'),
          value: 'finished',
          pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT],
        },
      ]
      return choices.filter((choice) => choice.pickupTypes.includes(this.order.pickup_type))
    },
    finishOrderButtonIsActive() {
      if (this.order.status !== 'new') {
        return true
      }
    },
    cancelOrderButtonVisible() {
      // show button if menu_source not pyszne or order old_localization filled
      return ![MENU_SOURCE_CHOICES.PYSZNE].includes(this.order.menu_source) || this.order.old_localization
    },
    printOrderConfirmationButtonIsActive() {
      if (this.order.delivery_type === null) return false
      if (this.orderConfirmationIsPrinting) return false
      if (!['new', 'finished'].includes(this.order.status)) {
        return true
      }
    },
    finishOrderDisabled() {
      return this.hasSplitBills && this.baseBillPostions.length > 0
    },
    billPrintDisabled() {
      return this.order.is_printed || this.order.delivery_type == null || (this.hasSplitBills && !this.baseBillId)
    },
    invoicePrinted() {
      return this.cart.isInvoiced
    },
    acceptOrDeclineDisabled() {
      // You should not be able to accept or decline an order when:
      return (
        !this.promisedTimeValue &&
        !this.order.promised_time && // order's promised time value is not set to some value AND
        !this.decline &&
        !this.accept
      ) // both decline and accept flags are set to false (meaning no buttons to accept or decline an order were clicked)
    },
    pyszneTwoPhase() {
      return (
        this.order.menu_source === MENU_SOURCE_CHOICES.PYSZNE &&
        this.isOrderNew &&
        this.order.order_customer.phone === ''
      )
    },
    isTransferred() {
      return this.order.old_localization
    },
    isPromisedTimePickTimeChangeAvailable() {
      return (
        this.order.promised_time &&
        (this.order.menu_source === MENU_SOURCE_CHOICES.NEW_ERESTAURANT ||
          (this.order.menu_source === MENU_SOURCE_CHOICES.PYSZNE && this.$hasModuleAccess('takeaway-api')))
      )
    },
    showPromisedTimeComponent() {
      return !this.order.promised_time || this.isPromisedTimePickTimeChangeAvailable
    },
    isOrderNew(){
      return this.order.status === ORDER_STATUSES.ORDER_STATUS_NEW
    }
  },
  beforeCreate() {
    if (!this.$store.state[STORE]) this.$store.registerModule(STORE, store)
    else this.$store.commit(`${STORE}/resetState`)
  },
  created() {
    this.$store.commit(`${STORE}/setOrderId`, this.id)
    this.getOrder(() => {
      this.deliveryTypeChosen = this.order.delivery_type
      // TODO: when in order will be info about bills this request will be unneccesary
      this.getBills()
    })
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs['okButton']?.focus) this.$refs['okButton'].focus()
    }, 100)
  },
  destroyed() {
    this.$store.commit(`${STORE}/resetState`)
    this.$store.unregisterModule(STORE)
  },
  methods: {
    ...mapActions(STORE, [
      'getOrder',
      'changeFinishedOrderStatus',
      'updateOrder',
      'acceptOrDeclineOrder',
      'closeOrder',
      'transferOrder',
    ]),
    ...mapActions({
      getOrders: '_orders/getOrders',
      goToDefaultOrdersList: 'goToDefaultOrdersList',
    }),
    ...mapMutations(['setBrand']),
    closeFinishOrderModal() {
      setTimeout(() => {
        if (this.$refs['okButton']?.focus) this.$refs['okButton'].focus()
      }, 500)
    },
    editOrder() {
      this.setBrand({ brandId: this.order.brand_id, brandName: this.order.brand_name })
      this.$emit('editOrder', { id: this.orderId, order: this.order, hasSplitBills: this.hasSplitBills })
    },
    _changeFinishedOrderStatus(choice) {
      this.changeFinishedOrderStatus({
        selectedFinishOrderChoice: choice,
        onSuccess: () => {
          this.finishOrderStatusSet = true
          this.$eventBus.$emit('onFinishOrderModalHide')
        },
      })
      this.$store.commit(`${STORE}/setCart`, this.cart)
    },
    async printOrderConfirmation() {
      const { error } = await API.printReceipt({ order_id: this.order.id })
      if (!error) {
        this.$toasted.show(this.$t('message.orderDetails.printOrderConfirmation.success'), {
          type: 'success',
          icon: {
            name: 'check',
          },
          duration: 3000,
        })
        this.$store.commit('_orders/updateOrderList', { id: this.order.id, is_printed: true })
        this.orderConfirmationIsPrinting = true
      }
    },
    isPaymentTypeSet() {
      return this.order.payment !== this.paymentDefault
    },
    isDelivery() {
      return this.order.pickup_type ? this.order.pickup_type === PICKUP_TYPE_DELIVERY : false
    },
    isOrderFinished() {
      return !!this.order.finished_type
    },
    async printBillMethod() {
      const payment = this.order.payment
      if (!payment?.length) {
        this.$toasted.show(this.$t('message.orderDetails.paymentTypeNotSet'), {
          type: 'error',
          duration: 3000,
        })
        return
      }
      // order.pickup type needs to be checked here, it cannot be checked in data () because we need to access this.order which is binded after data ()
      // order.finished_type as well
      if (this.closeOrderOnPrintBillButton && !this.isDelivery() && !this.isOrderFinished()) {
        let orderData = {
          finished_type: ORDER_STATUSES.ORDER_STATUS_FINISHED,
          payment: this.order.payment,
        }
        this.closeOrder({
          orderData: orderData,
          onSuccess: () => {
            this.clearResponseErrors({})
          },
          onError: (data) => {
            if (data) this.setResponseErrors(data)
          },
          orderId: this.order.id,
        })
      }
      this.printBillRequest(this.order.id, this.baseBillId)
    },
    responseOrder(acceptOrDecline, getOrders = false, data = {}) {
      // base method to call accept/cancel integration api
      data.order_id = this.order.id
      this.acceptOrDeclineOrder({
        apiUrl: acceptOrDecline,
        sendData: data,
        onSuccess: () => {
          if (getOrders) {
            // goes to ordersList and fetches orders
            this.getOrders({ filters: { archive: false }, loading: true })
          }
        },
        onError: (error) => {
          this.setResponseErrors(error)
        },
      })
    },
    _declineTransfer() {
      this.transferOrder({
        orderId: this.order.id,
        localizationId: this.order.old_localization.id,
        onSuccess: () => {
          this.getOrders({ filters: { archive: false }, loading: true })
        },
      })
    },
    acceptOrDecline() {
      // in case long loading, user might leave modal with pressing key "ESC"
      if (this.orderRequestStatus !== 'loading') {
        if (this.isOrderNew) {
          // IF ACCEPTED
          if (!this.decline && (this.accept || this.promisedTimeValue !== '')) {
            // If no promisedTimeValue was chosen (because user didn't choose any value)
            if (this.promisedTimeValue === '') {
              let promisedTime = null
              // if order has no promised_time - set actual time
              if (this.order.promised_time === null) {
                promisedTime = this.$moment().format(POS_API_DATETIME_INPUT_FORMAT)
              } else {
                // If order had promised_time set - set it again to that value
                promisedTime = this.order.promised_time
              }
              this.updateOrderPromisedTimeAndStatus(promisedTime)
              // If promisedTimeValue chosen - add it to actual time
            } else {
              const promisedTime = this.$moment()
                .add(this.promisedTimeValue, 'minutes')
                .format(POS_API_DATETIME_INPUT_FORMAT)
              this.updateOrderPromisedTimeAndStatus(promisedTime, this.promisedTimeValue)
            }
          } else if (this.decline && this.order.old_localization !== null) {
            this._declineTransfer()
            // IF CANCELED - cancel order (cancel button is not shown for pyszne)
          } else if (this.decline && !this.accept) {
            this.responseOrder('cancel')
          }
        } else {
          this.$emit('goToDefaultOrdersList')
        }
      }
      this.$emit('closeModal')
    },
    closeOrderDetails() {
      this.$emit('goToDefaultOrdersList')
      this.$emit('closeModal')
    },
    updateOrderPromisedTimeAndStatus(promisedTime, minutes = null) {
      const orderData = {
        id: this.cart.id,
        promised_time: promisedTime,
        status: ORDER_STATUSES.ORDER_STATUS_ACCEPTED,
        minutes: minutes,
        delivery_type: this.deliveryTypeChosen,
      }
      // For integrations, accept order and send either promised_time or minutes value
      if (
        [
          MENU_SOURCE_CHOICES.PYSZNE,
          MENU_SOURCE_CHOICES.NEW_ERESTAURANT,
          MENU_SOURCE_CHOICES.RESTAUMATIC,
          MENU_SOURCE_CHOICES.UPMENU,
          MENU_SOURCE_CHOICES.UBEREATS,
          MENU_SOURCE_CHOICES.GLOVO,
          MENU_SOURCE_CHOICES.ORDER_SMART,
          MENU_SOURCE_CHOICES.RESTABLO,
          MENU_SOURCE_CHOICES.WOLT,
        ].includes(this.order.menu_source)
      ) {
        this.responseOrder('accept', true, {
          promised_time: promisedTime,
          minutes: minutes,
          predicted_promised_time: this.cart.predictedPromisedTime,
        })
      } else {
        this.updateOrder({
          order: orderData,
          onSuccess: () => {
            this.getOrders({ filters: { archive: false }, loading: true })
          },
        })
      }
    },
    showFinishOrderModal() {
      this.$eventBus.$emit('onShowFinishOrderModal')
    },
    restoreOrder() {
      const orderData = {
        id: this.cart.id,
        status: ORDER_STATUSES.ORDER_STATUS_ACCEPTED,
      }
      this.updateOrder({
        order: orderData,
        refresh: true,
        onSuccess: () => {
          this.$toasted.show(this.$t('message.orderDetails.orderRestored'), {
            type: 'success',
            icon: {
              name: 'check',
            },
            duration: 3000,
          })
        },
        onError: (error) => {
          const msg = error.response?.data?.non_field_errors || this.$t('message.common.anErrorOccured')
          this.$toasted.show(msg, {
            type: 'error',
            duration: 3000,
          })
        },
      })
    },
    changePromisedTime(promisedTimeValue) {
      // set promisedTime directly if received full date (in string) or promisedTimeValue (in minutes) if value
      if (typeof promisedTimeValue === 'string' && promisedTimeValue !== '')
        this.order.promised_time = promisedTimeValue
      else this.promisedTimeValue = promisedTimeValue
    },
    async getBills() {
      this.gettingBills = true
      try {
        await this.$store.dispatch(`${STORE}/getBills`)
      } catch ({ errorStatus, errorValue }) {
        const msg =
          (errorStatus === 400 || errorStatus === 403) && errorValue
            ? errorValue
            : this.$t('message.common.anErrorOccured')
        this.$toasted.error(msg, {
          duration: 7000,
        })
      }
      this.gettingBills = false
    },
    getErrorValue(error) {
      const response = error?.response || null
      const data = response?.data || null
      const errorKey = data ? Object.keys(data) : null
      const errorValue = errorKey?.length ? data[errorKey[0]] : null
      return errorValue
    },
    setActiveMobileView(value) {
      this.activeMobileView = value
    },
  },
}
</script>

<style scoped lang="scss">
.order-details-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 100%;
  grid-gap: 10px;
  height: 100%;
  padding: 10px;
  background-color: #f0f4f7;
  overflow-y: auto;

  @include media-breakpoint-down(md) {
    padding: 5px;
    grid-gap: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
    grid-gap: 0;
  }
}
.message-centred {
  margin: 0 15px;
}
.widget-details {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.widget-cart {
  overflow-y: auto;
  overflow-x: hidden;
}

.action {
  &--v-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--ok {
    min-height: 53px;
  }

  &.disabled,
  &[disabled] {
    background-color: #abb5be;
    border-color: #abb5be;
  }

  &__icon {
    font-size: 24px;
  }

  &__text {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    display: block;
    text-transform: uppercase;
    margin-left: 0;
    margin-top: 5px;

    &--inline {
      display: inline-block;
    }

    &--lg {
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
    }
  }

  &__shortcut {
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    min-width: 32px;
    min-height: 24px;
    display: inline-block;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
}

.order-details-footer-buttons {
  @include media-breakpoint-down(md) {
    margin-left: -2px;
    margin-right: -2px;
    .col {
      padding-left: 2px;
      padding-right: 2px;
    }
    .btn-lg {
      padding: 6px;
      i {
        font-size: 16px;
      }
    }
  }
}

.widget-info {
  overflow-y: auto;
  overflow-x: hidden;
}

.printing-error-button {
  border: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
}

.printing-error-button i {
  margin-bottom: 0;
}

.integration-error {
  &-icon {
    max-width: 50px;
    min-width: 25px;
    height: 100%;
    max-height: 30px;
    margin-left: -0.5rem;
  }
  &-info {
    font-size: 12px;
  }
}

@media (min-width: 1500px) {
  .printing-error-button {
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translate(-50%, -50%);
  }
}
.center-checkbox {
  position: relative;
  transform: translate(50%, 12%);
}
.customer-details .fas {
  color: #3c4c58;
}

@media (max-width: 425px) {
  .integration-error {
    &-icon {
      margin-left: -1rem;
      margin-right: 0;
    }
    &-info {
      font-size: 10px;
    }
  }
}
.accept-btn{
  box-shadow: inset 10px 0 0 #24d17e;

  @media (max-width: 425px) {
      padding: 13px 0.2rem;
    }
  
  &:hover{
    box-shadow: inset 20px 0 0 #24d17e;
  }

  &__promised-time{
    background-color: white;
    color: #4e4e4e;
    padding: 6px 8px;
    border-radius: 4px;
    margin-left: 14px;

    @media (max-width: 425px) {
      font-size: 11px;
      padding: 2px 4px;
    }
  }
}

.decline-btn{
  box-shadow: inset 10px 0 0 #E85555;

  &:hover{
    box-shadow: inset 20px 0 0 #E85555;
  }
}
</style>
