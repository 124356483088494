<template>
  <b-modal
    id="ingredients-modal"
    ref="modal"
    dialog-class="modal-fullscreen-dialog"
    content-class="modal-fullscreen-content"
    body-class="p-0"
    scrollable
    tabindex="-1"
    @hide="hide()"
    @show="show()"
    @shown="shown()"
  >
    <template #modal-header>
      <div class="modal-title mr-2">
        {{ $t('message.multiChoiceEntryModal.ingredients') }}
      </div>
      <div class="input-group">
        <input
          ref="searchInput"
          v-model="searchKeyword"
          type="text"
          class="form-control input-lg"
          :placeholder="$t('message.multiChoiceEntryModal.search')"
          @input="searchEntry"
        />
      </div>
      <button id="save-ingredients-btn--footer" type="button" class="btn btn-success save-btn" @click="hide()">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark small">esc</kbd> {{ $t('message.multiChoiceEntryModal.save') }}
      </button>
    </template>
    <IngredientsList
      :key="'availableIngredients' + refreshKey"
      :title="$t('message.multiChoiceEntryModal.ingredients')"
      :empty-list-message="$t('message.multiChoiceEntryModal.noIngredients')"
      :closeable="true"
      :items="ingredientsList"
      :button-class="'pp-button--yellow'"
      variant="selected"
      @onIngredientClick="
        (item) => {
          $emit('removeIngredient', item)
          refreshKey++
        }
      "
    />
    <IngredientsList
      :key="'removedIngredients' + refreshKey"
      :title="$t('message.multiChoiceEntryModal.deletedIngredients')"
      :empty-list-message="$t('message.multiChoiceEntryModal.noIngredients')"
      :items="removedIngredients"
      :button-class="'pp-button--alert'"
      variant="deleted"
      @onIngredientClick="
        (item) => {
          $emit('restoreIngredient', item)
          refreshKey++
        }
      "
    />
    <template #modal-footer class="p-0">
      <button id="save-ingredients-btn" type="button" class="btn btn-success btn-lg btn-block" @click="hide()">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark small">esc</kbd> {{ $t('message.multiChoiceEntryModal.save') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import IngredientsList from './IngredientsList'
/* global $, */
export default {
  name: 'IngredientsModal',
  components: { IngredientsList },
  props: ['ingredientsList', 'removedIngredients'],
  data: function () {
    return {
      searchKeyword: '',
      searchResults: this.ingredientsList,
      refreshKey: 0,
    }
  },
  computed: {
    ...mapGetters(['currency']),
  },
  updated() {
    // Focusing on input after every child click -- fix for not loosing focus on Modal after removing mcg/ingredient
    document.addEventListener('click', this.focusOnInput)
  },
  methods: {
    hide() {
      this.$bvModal.hide('ingredients-modal')
      this.$emit('onModalToggle', false)
    },
    show() {
      this.$emit('onModalToggle', true)
    },
    shown() {
      // Focusing on input on every Modal show
      this.focusOnInput()
    },
    searchEntry() {
      this.searchResults = []
      if (this.searchKeyword !== '') {
        const words = this.searchKeyword.split(' ')
        if (words.length > 0 && words[0].length > 0) {
          this.ingredientsList.forEach((entry) => {
            let include = true
            words.forEach((word) => {
              if (!entry.name.toLowerCase().includes(word.toLowerCase())) {
                include = false
              }
            })
            if (include) {
              this.searchResults.push(entry)
            }
          })
        }
      }
    },
    getEntriesArray() {
      if (!this.searchKeyword) {
        return this.ingredientsList
      } else {
        return this.searchResults
      }
    },
    focusOnInput() {
      if (this.$refs.searchInput) this.$refs.searchInput.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.ingredients-sections-divider {
  border-bottom: 2px solid #bbbbbb;
  width: 100%;
}
.save-btn {
  width: 200px;
  margin-left: 15px;
}
</style>
