<template>
  <li class="nav-item">
    <b-button
      v-shortkey="['f1']"
      variant="success"
      class="nav-link nav-link-button"
      :class="{ 'router-link-exact-active': isNewOrderPapu3Active }"
      @shortkey="_onClick()"
      @click="_onClick()"
    >
      <slot name="content">
        <kbd class="nav-link__shortcut">F1</kbd>
        <i class="nav-link__icon fas fa-car" aria-hidden="true" />
        <span class="nav-link__text">{{ $t('message.navMain.newOrder') }}</span>
      </slot>
    </b-button>
  </li>
</template>

<script>
import { newOrderMixins } from '@/mixins/newOrderMixins'

export default {
  name: 'ItemNewOrderPapu3',
  mixins: [newOrderMixins],
  computed: {
    isNewOrderPapu3Active() {
      return this.$route.name === 'new-order' && this.$route.query.orderOnSite
    },
  },
  methods: {
    _onClick() {
      if (this.isMultiBrand) this.showBrandsModal({ orderPapu3: true, setBrand: true, showOnline: false })
      else this.openNewOrderModal()
    },
    openNewOrderModal() {
      this.$eventBus.$emit('showOrderPapu3Modal')
    },
  },
}
</script>
