import VueI18n from 'vue-i18n'
import Vue from 'vue'
import { en, pl, de } from './messages'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages: {
    en,
    pl,
    de,
  },
})
