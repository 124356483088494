import { RootState } from '@/store'

const setMenus = (state: { menus: any }, payload: any) => {
  state.menus = payload
}

const setMenu = (state: RootState, data: any) => {
  const idx = state.menus.findIndex((menu) => menu.json?.id === data.json?.id)
  if (idx >= 0) {
    state.menus[idx] = data
    state.menus = [...state.menus]
  }
}

const setGetMenuRequestStatus = (state: RootState, value: string) => {
  state.getMenuRequestStatus = value
}

const setBrand = (
  state: { currentBrandId: any; currentBrandName: any },
  brandData: { brandName: any; brandId: any }
) => {
  const { brandName, brandId } = brandData
  state.currentBrandId = brandId
  state.currentBrandName = brandName
}

const setSource = (state: RootState, source: number) => {
  state.currentSource = source
}

const setNotifications = (state: { notifications: any }, notifications: any) => {
  state.notifications = notifications
}
const setStateLoadedFromSession = (state: { stateLoadedFromSession: any }, value: any) => {
  state.stateLoadedFromSession = value
}

const setNewOrdersCount = (state: { newOrdersCount: any }, count: any) => {
  state.newOrdersCount = count
}

const setHalfPizzaSettings = (state: { halfPizzaSettings: any }, value: any) => {
  state.halfPizzaSettings = value
}

const setDelayOrdersListRefresh = (state: { delayOrdersListRefresh: any }, value: any) => {
  state.delayOrdersListRefresh = value
}

const setWindowWidth = (state: { windowWidth: number }, width: number) => {
  state.windowWidth = width
}

export default {
  setMenus,
  setMenu,
  setBrand,
  setSource,
  setNotifications,
  setStateLoadedFromSession,
  setNewOrdersCount,
  setHalfPizzaSettings,
  setDelayOrdersListRefresh,
  setWindowWidth,
  setGetMenuRequestStatus,
}
