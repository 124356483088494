<template>
  <auth-layout>
    <h1 class="sr-only">{{ $t('message.auth.signInButton') }}</h1>
    
    <div class="auth-view">
      <transition-group name="fade" tag="div">
        <div
          v-if="hasUserRole([ROLE.OWNER]) && localizations.length > 1"
          key="selectLocalization"
          class="auth-form-container localization"
        >
          <h2 class="sr-only">
            {{ $t('message.auth.selectLocalization') }}
          </h2>

          <b-form ref="selectLocalizationForm" class="auth-form" @submit.prevent="selectLocalization">
            <b-form-select
              v-model="selectedLocalizationId"
              :options="[{ id: null, name: $t('message.auth.selectLocalization'), disabled: true }, ...localizations]"
              value-field="id"
              text-field="name"
              class="mb-3 form-control localization__select"
              size="lg"
            />

            <b-button
              v-shortkey="['enter']"
              :variant="formLocalizationIsEmpty ? 'secondary' : 'primary'"
              block
              size="lg"
              :disabled="formLocalizationIsEmpty"
              type="submit"
              @shortkey="selectLocalization"
            >
              <b-spinner v-if="selectLocalizationProcessing" small />
              <span>{{ $t('message.common.confirm') }}</span>
            </b-button>
          </b-form>
        </div>

        <div v-else key="signIn" class="d-flex flex-column align-items-center">
          <transition name="fade">
            <b-alert
              v-if="appLoaded && loggedOutReason === 'Invalid token'"
              show
              variant="danger"
              class="auth-element text-center"
            >
              {{ $t('message.auth.loggedOutInvalidToken') }}
            </b-alert>
          </transition>

          <div v-if="appLoaded" class="auth-form-container">
            <b-form ref="signInForm" class="auth-form" @submit.prevent="signIn()">
              <b-form-group>
                <b-form-input
                  id="email-address"
                  v-model="formInputs.email"
                  type="email"
                  class="form-control"
                  name="email-address"
                  required
                  :placeholder="$t('message.auth.email')"
                />

                <small v-if="validationErrors.email" class="form-text text-danger">
                  {{ validationErrors.email }}
                </small>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  id="password"
                  v-model="formInputs.password"
                  type="password"
                  class="form-control"
                  required
                  :placeholder="$t('message.auth.password')"
                >
                </b-form-input>
              </b-form-group>

              <small v-if="validationErrors.non_field_errors" class="form-text text-danger mb-2">
                {{ validationErrors.non_field_errors }}
              </small>

              <b-button
                v-shortkey="['enter']"
                :variant="formIsEmpty ? 'secondary' : 'primary'"
                block
                size="lg"
                :disabled="formIsEmpty"
                @click="signIn()"
                @shortkey="signIn()"
              >
                <b-spinner v-if="signInRequestStatus === 'loading'" small />
                <span>{{ $t('message.auth.signInButton') }}</span>
              </b-button>
            </b-form>

            <div v-if="appLoaded" class="auth-password-reset">
              <b-link to="password-reset">{{ $t('message.auth.forgotPassword') }}</b-link>

              <b-link id="help-support" class="help-support">{{ $t('need_help') }}</b-link>
              
              <b-tooltip target="help-support" placement="bottom">
                <HelpData class="text-left p-2" :only-cs="true" />
                
                <b-button variant="primary" block class="mb-2" @click="reportBug()">
                  <i class="fas fa-bug mr-2" />
                  {{ $t('report_sugestion') }}
                </b-button>
              </b-tooltip>
            </div>
          </div>
          
          <div v-if="getMenuRequestStatus === 'loading'" class="menu-loading">
            <b-spinner size="sm" variant="primary" />
            <span class="ml-3">{{ $t('message.auth.loadingMenu') }}...</span>
          </div>
        </div>
      </transition-group>
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout'
import { mapGetters } from 'vuex'
import { ROLE } from '@/common/constants'
import HelpData from '@/components/navigation/HelpData'
export default {
  name: 'UserAuth',
  components: { AuthLayout, HelpData },
  props: ['loggedOutReason'],
  data: function () {
    return {
      formInputs: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      validationFields: ['username', 'email', 'password1', 'password2', 'non_field_errors'],
      validationErrors: {
        username: '',
        email: '',
        password: '',
        non_field_errors: '',
      },
      accountCreated: false,
      responseMessage: '',
      appLoaded: false,
      ROLE,
      selectedLocalizationId: null,
      selectLocalizationProcessing: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['signInRequestStatus', 'localizations', 'hasUserRole']),
    ...mapGetters(['getMenuRequestStatus']),
    formIsEmpty() {
      return this.appLoaded && (this.formInputs.email.length === 0 || this.formInputs.password.length === 0)
    },
    formLocalizationIsEmpty() {
      return this.selectedLocalizationId === null
    },
  },
  beforeMount() {
    window.OnSubmitStoredCredentials = (email, password, autoSubmit) => {
      this.formInputs.email = email
      this.formInputs.password = password
      if (autoSubmit) {
        this.signIn()
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.appLoaded = true
    }, 300)
  },
  methods: {
    validateForm(errorData) {
      this.validationFields.forEach((fieldName) => {
        if (errorData[fieldName] !== undefined) {
          this.validationErrors[fieldName] = errorData[fieldName].join(' ')
        } else {
          this.validationErrors[fieldName] = ''
        }
      })

      if (typeof errorData === 'string'){
        this.validationErrors.non_field_errors = errorData
      }
    },
    cleanForm() {
      this.formInputs.username =
        this.formInputs.email =
        this.formInputs.password =
        this.formInputs.confirm_password =
          ''
    },
    signIn() {
      const credentials = { email: this.formInputs.email, password: this.formInputs.password }
      this.$store.dispatch('auth/signIn', {
        credentials,
        onError: (error) => {
          this.validateForm(error)
        },
        onSuccess: () => {
          this.appLoaded = false
        },
      })
    },
    signUp() {
      const credentials = {
        email: this.formInputs.email,
        username: this.formInputs.username,
        password1: this.formInputs.password,
        password2: this.formInputs.confirmPassword,
      }
      this.$store.dispatch('auth/signUp', {
        credentials,
        onSuccess: (response) => {
          this.cleanForm()
          this.accountCreated = true
          this.responseMessage = response.data.detail
        },
        onError: (error) => {
          this.validateForm(error)
        },
      })
    },
    async selectLocalization() {
      await this.$store.dispatch('auth/getMe', { id: this.selectedLocalizationId })
    },
    reportBug() {
      if (window.Ybug) {
        window.Ybug.open()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.auth-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  padding: 0 20px;
}
.auth-form {
  width: 100%;
}

.auth-view {
  position: relative;
}

.auth-password-reset {
  margin-top: 1em;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.localization {
  min-height: 190px;
  justify-content: flex-start;

  &__select {
    min-width: 320px;
  }
}

@media (max-width: 1000px) {
  .auth-card {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .auth-card {
    width: 100%;
    max-width: unset;
  }
  .auth-card-container {
    width: 100%;
  }
  .auth-logo {
    img {
      width: 220px;
    }
  }
}
.menu-loading {
  padding: 1rem;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
</style>
