<template>
  <b-modal
    id="mealSetItemModal"
    ref="mealSetItemModal"
    v-model="visible"
    dialog-class="modal-fullscreen-dialog"
    content-class="modal-fullscreen-content"
    scrollable
    :title="
      mealSetItemForm.itemIndex !== null
        ? $t('message.mealSets.saveMealSetItem')
        : $t('message.mealSets.addMealSetItem')
    "
    @hidden="init()"
    @close.prevent="hide"
  >
    <b-form-group
      v-if="!isHalfPizzaMealSet"
      id="mealSetItemCount"
      :label="$t('message.mealSets.mealSetItemQuantity')"
      class="d-flex mb-3"
    >
      <b-input-group style="max-width: 300px">
        <b-input-group-append style="width: 35%">
          <b-button variant="danger" block @click="changeQuantity('decrease')"> -</b-button>
        </b-input-group-append>
        <b-form-input
          id="meal-set-count-input"
          v-model="mealSetItemForm.count"
          class="h-auto"
          :formatter="quantityInputFormatter"
          @blur=";['', 0].includes(mealSetItemForm.count) ? (mealSetItemForm.count = 1) : null"
        ></b-form-input>
        <b-input-group-append style="width: 35%">
          <b-button variant="success" block @click="changeQuantity('increase')"> +</b-button>
        </b-input-group-append>
        
      </b-input-group>
    </b-form-group>

    <b-form-group v-if="!isHalfPizzaMealSet" :label="$t('message.mealSets.mealSetItemDiscount')">
      <meal-set-discount
        id="mealSetItemDiscount"
        ref="mealSetItemDiscount"
        :discount-type="mealSetItemForm.discount_type"
        :discount-value="mealSetItemForm.discount_value"
        @discountChange="setDiscount"
      >
      </meal-set-discount>
    </b-form-group>

    <div class="divider" />

    <b-alert v-if="inErestaurant" show
      ><i class="fas fa-info-circle mr-2" />{{ $t('mealset_in_erestaurant_info') }}</b-alert
    >

    <b-form-group :label="$t('message.mealSets.chooseCategory')">
      <b-radio-group
        v-model="mealSetItemForm.category"
        v-validate="'required'"
        buttons
        button-variant="outline-primary"
        class="d-flex flex-wrap d-md-inline btn-group--center"
        name="category"
      >
        <b-radio
          v-for="cat in categories"
          :key="cat.id"
          :value="getMealSetItemObject(cat)"
          class="mb-2"
          :disabled="inErestaurant && !cat.in_erestaurant"
          @input="categoryChanged()"
        >
          {{ cat.name }}
        </b-radio>
      </b-radio-group>
      <input-error-msg field="category" :_errors="errors"></input-error-msg>
    </b-form-group>

    <template v-if="mealSetItemForm.category && sizes.length > 1">
      <div class="divider divider--dashed" />

      <b-form-group label-class="justify-content-between">
        <template #label>
          {{ $t('message.mealSets.chooseSizes') }}
          <b-button variant="link" size="sm" @click="addOrRemoveAllSizes()">
            {{ $t('message.mealSets.checkUncheckAll') }}
          </b-button>
        </template>
        <input-error-msg field="size" :_errors="errors" class="mb-1" />
        <b-checkbox-group
          v-model="mealSetItemForm.sizes"
          v-validate="'atLeastOne|required'"
          buttons
          button-variant="outline-primary"
          name="size"
        >
          <b-checkbox
            v-for="(size, sizeIndex) in sizes"
            :key="size.id"
            :value="getMealSetItemObject(size)"
            :disabled="inErestaurant && !size.in_erestaurant"
          >
            {{ getSizeName(size) }}
          </b-checkbox>
        </b-checkbox-group>
      </b-form-group>
    </template>

    <template v-if="mealSetItemForm.sizes && mealSetItemForm.sizes.length > 0">
      <div class="divider divider--dashed" />

      <b-form-group label-class="justify-content-between">
        <template #label>
          {{ $t('message.mealSets.chooseMeals') }}
          <b-button variant="link" size="sm" @click="addOrRemoveAllMealGroups()">
            {{ $t('message.mealSets.checkUncheckAll') }}
          </b-button>
        </template>

        <input-error-msg field="meal" :_errors="errors" class="mb-1" />

        <b-checkbox-group
          v-model="chosenMealGroups"
          v-validate="'atLeastOne|required'"
          buttons
          button-variant="outline-primary"
          class="meals"
          name="meal"
          @change="itemChanged = true"
        >
          <b-checkbox
            v-for="mealGroup in mealGroups"
            :key="mealGroup.id"
            v-b-tooltip.hover.bottom="!mealGroup.in_erestaurant ? 'Pozycja niedostępna dla e-restauracji' : ''"
            :value="mealGroup.id"
            class="meal mealGroup"
            :disabled="inErestaurant && !mealGroup.in_erestaurant"
          >
            {{ mealGroup.name }}
          </b-checkbox>
        </b-checkbox-group>
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-col class="d-inline-flex justify-content-between px-0">
        <b-button v-if="!isMobile" size="lg" variant="secondary" class="mr-4" @click="hide()">
          {{ $t('message.common.cancel') }}
        </b-button>
        <b-button size="lg" block variant="success" @click="saveMealSetItem()">
          {{
            mealSetItemForm.itemIndex !== null
              ? $t('message.mealSets.saveMealSetItem')
              : $t('message.mealSets.addMealSetItem')
          }}
        </b-button>
      </b-col>
    </template>
    <DiscardChangesModal id="itemDiscardChangesModal" @discard-changes="discardMealSetItem" />
  </b-modal>
</template>

<script>
import { MEALSET_ITEM_CONTEXT_CHOICES, MEALSET_TYPES } from '@/common/constants'
import MealSetDiscount from './MealSetDiscount'
import { commonMixins } from '@/mixins/commonMixins'
import DiscardChangesModal from '@/components/DiscardChangesModal'
import { isMobile, addResizeEvent, removeResizeEvent } from '@/utils/common'

export default {
  name: 'MealSetItemModal',
  components: { MealSetDiscount, DiscardChangesModal },
  mixins: [commonMixins],
  props: ['menu', 'type', 'inErestaurant'],
  data() {
    return {
      chosenMealGroups: [],
      mealSetItemForm: {
        category: null,
        sizes: [],
        meals: [],
        count: 1,
        discount_type: null,
        discount_value: null,
        itemIndex: null,
      },
      isInit: true,
      itemChanged: false,
      visible: false,
      isMobile: false,
    }
  },
  computed: {
    categories() {
      return this.menu.categories
    },
    category() {
      return this.categories.find((cat) => cat.id === this.mealSetItemForm.category.id)
    },
    sizes() {
      // returns sizes from chosen category
      return this.category.sizes
    },
    mealGroups() {
      // returns available to choose mealGroups
      let category = this.menu.categories.find((cat) => cat.id === this.mealSetItemForm.category.id)
      let mealGroups = []
      category.sizes.forEach((size) => {
        // filtering sizes to match only chosen sizes
        if (!this.mealSetItemForm.sizes.find((categorySize) => categorySize.id === size.id)) return
        size.meals.forEach((meal) => {
          // If mealGroup not already added to mealGroups - add it
          let mealGroupAlreadyInArray = mealGroups.find((mealGroup) => mealGroup.id === meal.meal_group.id)
          if (!mealGroupAlreadyInArray) mealGroups.push(meal.meal_group)
        })
      })
      return [...mealGroups]
    },
    context() {
      let allSizes =
        this.mealSetItemForm.sizes && this.mealSetItemForm.sizes.length > 0
          ? this.mealSetItemForm.sizes.length === this.sizes.length
          : true
      let allMeals = this.chosenMealGroups ? this.chosenMealGroups.length === this.mealGroups.length : true
      // context describes which type of MealSetItem it is (category, size, meal)
      return allSizes && allMeals
        ? MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_CATEGORY
        : allMeals || this.chosenMealGroups.length === 0
        ? MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_SIZE
        : MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_MEAL
    },
    allSizes() {
      return (
        this.mealSetItemForm.sizes &&
        this.mealSetItemForm.sizes.length > 0 &&
        this.mealSetItemForm.sizes.length === this.sizes.filter(this.filterInErestaurant).length
      )
    },
    allMeals() {
      return this.chosenMealGroups
        ? this.chosenMealGroups.length === this.mealGroups.filter(this.filterInErestaurant).length
        : true
    },
    isEdit() {
      return !!this.mealSetItemForm.id
    },
    isHalfPizzaMealSet() {
      return this.type === MEALSET_TYPES.HALF_PIZZA
    },
  },
  watch: {
    mealSetItemForm: {
      handler() {
        const isInit = this.isInit
        if (!isInit && this.visible) {
          this.itemChanged = true
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$eventBus.$on('onMealSetItemModal', (item) => {
      if (item) {
        // Assign MealSetItemForm based on item
        this.mealSetItemForm = { ...item }
        if (item.context === MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_CATEGORY) {
          // Assign all sizes based on category
          this.assignItemFormArrayFromArray('sizes', this.category.sizes)
          // Assign all mealGroups from sizes
          this.assignMealGroupsFromSizes(this.category.sizes)
        } else if (item.context === MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_SIZE) {
          // Assign category based on sizes - not needed if category is in item
          // this.category = this.categories.find(cat => _.intersectionBy(item.sizes, cat.sizes, (s) => s.id))
          // Assign all mealGroups from sizes meals
          const sizes = this.intersectionObjectListsBy(this.category.sizes, item.sizes, 'id')
          this.assignMealGroupsFromSizes(sizes)
        } else if (item.context === MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_MEAL) {
          // Assign sizes based on meals
          const sizes = this.category.sizes.filter(
            (size) => this.intersectionObjectListsBy(size.meals, item.meals, 'id').length > 0
          )
          this.assignItemFormArrayFromArray('sizes', sizes)
          // Assign mealGroups based on meals
          let mealGroups = []
          sizes.forEach((size) => {
            const mealGroupsIds = this.intersectionObjectListsBy(size.meals, item.meals, 'id').map(
              (meal) => meal.meal_group.id
            )
            mealGroups.push(...mealGroupsIds)
          })
          this.chosenMealGroups = _.uniq(mealGroups)
          this.mealSetItemForm.meals = []
        }
      }
      this.isInit = false
      this.setIsMobile()
      addResizeEvent(this.setIsMobile)
    })
  },
  destroyed() {
    this.$eventBus.$off('onMealSetItemModal')
    removeResizeEvent(this.setIsMobile)
  },
  methods: {
    init() {
      this.chosenMealGroups = []
      this.mealSetItemForm = {
        category: null,
        sizes: [],
        meals: [],
        count: 1,
        discount_type: null,
        discount_value: null,
        itemIndex: null,
      }
      this.itemChanged = false
      this.isInit = true
    },
    hide() {
      if (this.itemChanged) {
        this.$bvModal.show('itemDiscardChangesModal')
      } else {
        this.visible = false
      }
    },
    quantityInputFormatter(value, event) {
      if (value == 0) return ''
      else if (value.length > 3) return value.substring(0, 3)
      else return value.replace(/[^\d]/g, '')
    },
    changeQuantity(value) {
      if (typeof this.mealSetItemForm.count === 'string') {
        this.mealSetItemForm.count = Number(this.mealSetItemForm.count.toString().replace(',', '.'))
      }
      if (value === 'increase') this.mealSetItemForm.count++
      else if (value === 'decrease' && this.mealSetItemForm.count > 1) this.mealSetItemForm.count--
    },
    getTitle() {
      return this.isEdit ? 'Edycja elementu zestawu' : 'Nowy element zestawu'
    },
    categoryChanged() {
      // remove already chosen sizes
      this.removeAllSizes()
      // add single size of category
      if (this.sizes.length === 1) this.addAllSizes()
    },
    addAllSizes() {
      // Assign all sizes based on category
      this.assignItemFormArrayFromArray('sizes', this.category.sizes.filter(this.filterInErestaurant))
    },
    addAllMealGroups() {
      this.chosenMealGroups = this.mealGroups.filter(this.filterInErestaurant).map((mg) => mg.id)
      this.itemChanged = true
    },
    removeAllSizes() {
      this.$set(this.mealSetItemForm, 'sizes', [])
    },
    removeAllMealGroups() {
      this.chosenMealGroups = []
      this.itemChanged = true
    },
    addOrRemoveAllSizes() {
      if (!this.allSizes) this.addAllSizes()
      else this.removeAllSizes()
    },
    addOrRemoveAllMealGroups() {
      if (!this.allMeals) this.addAllMealGroups()
      else this.removeAllMealGroups()
    },
    setDiscount(discountForm) {
      this.mealSetItemForm.discount_value = discountForm.discount_value
      this.mealSetItemForm.discount_type = discountForm.discount_type
    },
    saveMealSetItem() {
      if (!this.isHalfPizzaMealSet) {
        this.$refs.mealSetItemDiscount.$validator.validateAll().then((mealSetItemDiscountValidated) => {
          this.$validator.validateAll().then((result) => {
            if (!result || !mealSetItemDiscountValidated) return null
            this._saveMealSetItem()
          })
        })
      } else {
        this._saveMealSetItem()
      }
    },
    _saveMealSetItem() {
      let mealSetItemData = {
        ...this.mealSetItemForm,
        context: this.context,
        menu: this.menu.id,
        _rowVariant: 'warning',
        isChanged: true,
      }
      if (this.context === MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_CATEGORY) {
        mealSetItemData.sizes = []
        mealSetItemData.meals = []
      } else if (this.context === MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_SIZE) {
        mealSetItemData.meals = []
      } else if (this.chosenMealGroups.length > 0 && this.context === MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_MEAL) {
        // Assign meals based on mealGroups
        const sizes = this.intersectionObjectListsBy(this.category.sizes, this.mealSetItemForm.sizes, 'id')
        sizes.forEach((size) => {
          let meals = _.intersectionWith(size.meals, this.chosenMealGroups, (meal, mg) => meal.meal_group.id === mg)
          meals = meals.map((meal) => {
            return { id: meal.id, name: meal.name }
          })
          mealSetItemData.meals.push(...meals)
        })
      }
      this.$emit('saveMealSetItem', mealSetItemData)
      this.itemChanged = false
      this.hide()
    },
    discardMealSetItem() {
      this.$bvModal.hide('itemDiscardChangesModal')
      this.visible = false
    },
    getSizeName(size) {
      let name = size.name
      if (name === 'default') name = this.$t('message.common.default')
      return name
    },
    getMealSetItemObject(obj) {
      // returns json with id and name of object
      return { id: obj.id, name: obj.name }
    },
    assignItemFormArrayFromArray(obj, array) {
      this.mealSetItemForm[obj] = array.map((obj) => this.getMealSetItemObject(obj))
    },
    assignMealGroupsFromSizes(sizes) {
      let mealGroups = []
      sizes.forEach((size) => {
        const mealGroupsIds = size.meals.map((meal) => meal.meal_group.id)
        mealGroups.push(...mealGroupsIds)
      })
      this.chosenMealGroups = _.uniq(mealGroups)
      this.mealSetItemForm.meals = []
    },
    setIsMobile() {
      this.isMobile = isMobile()
    },
    filterInErestaurant(item) {
      return (this.inErestaurant && item.in_erestaurant) || !this.inErestaurant
    },
  },
}
</script>

<style lang="scss" scoped>
.mealsetitem {
  &-section {
    margin-top: 10px;
    &-alert {
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
    }
  }
}

.meals {
  margin-left: -5px;
  margin-right: -5px;
}
</style>
