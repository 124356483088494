import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import Types from '../../types/interface'
import router from '@/router'
import rootStore from '@/store'
import { __DEV__ } from '@/utils/global'
import { MENU_SOURCE_CHOICES } from '@/common/constants'

export class ApiService {
  [key: string]: any

  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL + 'api/',
    })

    if (sessionStorage.getItem('authToken')) {
      this.setAuthToken(sessionStorage.getItem('authToken'))
    }

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        // We need to fix it. Handle network errors in better way.
        if (['Network Error', 'timeout of 0ms exceeded'].includes(error.message)) {
          return new Promise(() => false)
        }

        if (error?.response?.status === 401) {
          rootStore.dispatch('auth/cleanAfterLogout')
          router.push({ name: 'auth', params: { loggedOutReason: 'Invalid token' } })
          return new Promise(() => false)
        } else {
          return Promise.reject(error)
        }
      }
    )
  }

  public setAuthToken(token: string | null | undefined) {
    this.axiosInstance.defaults.headers.common['Authorization'] = token ? 'Token ' + token : ''
  }

  public removeAuthToken() {
    delete this.axiosInstance.defaults.headers.common['Authorization']
  }

  async axiosCall<T>(config: AxiosRequestConfig) {
    if (__DEV__)
      console.log(`%cREQUEST|${config.method}: ${config.url}`, 'background: #00F; color: #fff', 'Request: ', config)
    try {
      const response = await this.axiosInstance.request<T>(config)
      if (__DEV__) console.log(`%cSUCCESS|${config.method}: ${config.url}`, 'background: #0F0; color: #000', response)
      const { data }: { data: any } = response
      return { error: null, data, response }
    } catch (error) {
      if (__DEV__) console.log(`%cERROR|${config.method}: ${config.url}`, 'background: #F00; color: #BADA55', error)
      const data: any = null
      return { error, data }
    }
  }

  /** Orders **/

  async getOrder(orderId: number) {
    return this.axiosCall({ method: 'get', url: `orders/order/${orderId}/` })
  }
  async getOrders(params: any) {
    return this.axiosCall({ method: 'get', url: `orders/order/`, params })
  }
  async updateOrder(orderId: number, data: Partial<Types.Order>) {
    return this.axiosCall({ method: 'patch', url: `orders/order/${orderId}/`, data })
  }
  async closeOrder(orderId: number, data: Partial<Types.Order>) {
    return this.axiosCall({ method: 'patch', url: `orders/order/${orderId}/finish/`, data })
  }
  async transferOrder(orderId: number, localization_id: number) {
    return this.axiosCall({ method: 'patch', url: `orders/order/${orderId}/transfer/`, data: { localization_id } })
  }
  async acceptOrDeclineOrder(orderId: number, apiUrl: string, data: any) {
    return this.axiosCall({ method: 'patch', url: `orders/order/${orderId}/${apiUrl}/`, data })
  }
  async addOrder(isOrderOnSite: boolean, data: Partial<Types.Order>) {
    return this.axiosCall({ method: 'post', url: 'orders/order/' + (isOrderOnSite ? 'on_site/' : ''), data })
  }

  async checkAreaDelivery(address: any, order_price: any) {
    return this.axiosCall({
      method: 'get',
      url: 'orders/deliveryarea/location_and_area_for_address/',
      params: { address, order_price },
    })
  }
  async regeocodeOrders(order_ids: Array<number>) {
    return this.axiosCall({ method: 'post', url: 'orders/order/regeocode-address/', data: { order_ids } })
  }
  async markOrderPrinted(orderId: number, data: any) {
    return this.axiosCall({ method: 'patch', url: `orders/order/${orderId}/`, data })
  }

  async getAddressHints(street_name: string) {
    return this.axiosCall({ method: 'get', url: 'orders/address/street_hint/', params: { street_name } })
  }

  /** Couriers **/

  async getCouriers() {
    return this.axiosCall({ method: 'get', url: 'orders/courier/' })
  }
  async getMapCouriers() {
    return this.axiosCall({ method: 'get', baseURL: process.env.VUE_APP_API_URL, url: '/courier/api/map/' })
  }
  async assignCourierToOrder(orderId: number | string, data: any) {
    return this.axiosCall({ method: 'patch', url: `orders/order/${orderId}/`, data })
  }
  async assignCourierToOrders(data: any) {
    return this.axiosCall({ method: 'post', url: `orders/order/assign_courier_to_orders/`, data })
  }

  /** Customer **/

  async findCustomer(phone: string) {
    return this.axiosCall({ method: 'get', url: 'orders/customer/find_customer/', params: { phone } })
  }
  async removeCustomerAddress(addressId: number, phoneNumber: string) {
    return this.axiosCall({ method: 'delete', url: `orders/address/${addressId}/?phone=${phoneNumber}` })
  }

  /** Discounts **/

  async getDiscounts() {
    return this.axiosCall({ method: 'get', url: 'discounts/discount/' })
  }
  async addDiscountCode(data: any) {
    return this.axiosCall({ method: 'post', url: 'discounts/discount/', data })
  }
  async deleteDiscountCode(discountCodeId: any) {
    return this.axiosCall({ method: 'delete', url: `discounts/discount/${discountCodeId}/` })
  }
  async checkDiscountCode(discountCode: string, brandUUID: string) {
    return this.axiosCall({
      method: 'get',
      url: 'discounts/discount/check_code/',
      params: { value: discountCode, brand_uuid: brandUUID },
    })
  }

  /** Users **/

  async getUsers(params: any) {
    return this.axiosCall({ method: 'get', url: 'orders/user/', params })
  }
  async getUser(userId: number | null) {
    return this.axiosCall({ method: 'get', url: `orders/user/${userId}/` })
  }
  async updateUser(userId: number | null, data: Partial<Types.User>) {
    return this.axiosCall({ method: 'patch', url: `orders/user/${userId}/`, data })
  }
  async createUser(data: Partial<Types.User>) {
    return this.axiosCall({ method: 'post', url: `orders/user/`, data })
  }
  async deleteUser(user_id: number) {
    return this.axiosCall({ method: 'post', url: `orders/user/remove_user/`, data: { user_id } })
  }
  async getUsersSummary(params: any) {
    return this.axiosCall({ method: 'get', url: 'orders/user/summary/', params })
  }
  async setUserActive(data: Types.setUserActiveData) {
    return this.axiosCall({ method: 'post', url: `orders/user/set_user_is_active/`, data })
  }

  /** Billings **/

  async getBillingPeriod(url: string) {
    return this.axiosCall({ method: 'get', url })
  }
  async getBillingPeriods(filters: any) {
    return this.axiosCall({ method: 'get', url: 'salaries/billing-period/', params: { ...filters } })
  }
  async getBillingPeriodsCurrent(filters: any) {
    return this.axiosCall({ method: 'get', url: 'orders/user/billing_periods/', params: { ...filters } })
  }
  async closeBillingPeriod(billing_period_id: number) {
    return this.axiosCall({ method: 'post', url: 'salaries/billing-period/finish/', data: { billing_period_id } })
  }
  async closeBillingPeriodById(id: number, data: any) {
    return this.axiosCall({ method: 'post', url: `salaries/billing-period/${id}/finish/`, data })
  }
  async openBillingPeriod(id: number, data: any) {
    return this.axiosCall({ method: 'post', url: `salaries/billing-period/${id}/open/`, data })
  }
  async createBillingPeriodEntry(data: any) {
    return this.axiosCall({ method: 'post', url: 'salaries/billing-period-entry/', data })
  }

  /** Shifts **/

  async getShift(shiftId: number) {
    return this.axiosCall({ method: 'get', url: `salaries/shift/${shiftId}/` })
  }
  async getShifts(filters: Types.GetShiftsFilters) {
    return this.axiosCall({ method: 'get', url: 'salaries/shift/', params: { ...filters } })
  }
  async getShiftsCurrent(filters: Types.GetShiftsCurrentFilters) {
    return this.axiosCall({ method: 'get', url: 'orders/user/shifts/', params: { ...filters } })
  }
  async startShift(data: Types.StartShiftData) {
    return this.axiosCall({ method: 'post', url: 'salaries/shift/start/', data })
  }
  async finishShift(data: Types.FinishShiftData) {
    return this.axiosCall({ method: 'post', url: 'salaries/shift/finish/', data })
  }
  async createShiftEntry(data: Types.CreateShiftEntryData) {
    return this.axiosCall({ method: 'post', url: 'salaries/shift-entry/', data })
  }

  /** Menu **/

  async getMenus() {
    return this.axiosCall({
      method: 'get',
      url: `menus/menu/`,
      params: {
        source: parseInt(MENU_SOURCE_CHOICES.DEFAULT),
        is_default: true,
      },
    })
  }
  async getMenu(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/menu/${id}/` })
  }

  /** Menu edit **/
  async updateMenu(menuId: number, data: any) {
    return this.axiosCall({ method: 'post', url: `menus/menu/${menuId}/edit/`, data })
  }
  async uploadMenuImage(menuId: number, data: any) {
    return this.axiosCall({ method: 'post', url: `menus/menu/${menuId}/upload_image/`, data })
  }
  async publishMenu(id: number) {
    return this.axiosCall({ method: 'post', url: `menus/menu/${id}/publish_menu/` })
  }

  /** Menu edit - Category **/
  async getCategories(menuId: number, fields = '') {
    return this.axiosCall({ method: 'get', url: `menus/category/?menu=${menuId}&fields=${fields}` })
  }
  async getCategory(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/category/${id}/` })
  }
  async getCategorySizes(categoryId: number) {
    return this.axiosCall({ method: 'get', url: `menus/size/?category=${categoryId}` })
  }
  async createCategory(data: Types.ICategory) {
    return this.axiosCall({ method: 'post', url: 'menus/category/', data })
  }
  async updateCategory(id: number, data: Partial<Types.ICategory>) {
    return this.axiosCall({ method: 'patch', url: `menus/category/${id}/`, data })
  }
  async deleteCategory(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/category/${id}/` })
  }
  async updateCategoriesPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/category/change_positions/`, data })
  }

  /** Menu edit - Size **/
  async getSizes(id: number, fields = '') {
    return this.axiosCall({ method: 'get', url: `menus/size/?category__menu=${id}&fields=${fields}` })
  }
  async getSize(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/size/${id}/` })
  }
  async createSize(data: Types.ISize) {
    return this.axiosCall({ method: 'post', url: 'menus/size/', data })
  }
  async updateSize(id: number, data: Partial<Types.ISize>) {
    return this.axiosCall({ method: 'patch', url: `menus/size/${id}/`, data })
  }
  async deleteSize(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/size/${id}/` })
  }
  async updateSizesPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/size/change_positions/`, data })
  }
  async mergeSize(id: number, data: { merged_size_id: number }) {
    return this.axiosCall({ method: 'post', url: `menus/size/${id}/merge/`, data })
  }

  /** Menu edit - SingleChoiceGroup **/
  async getSingleChoiceGroups(menuId: number) {
    return this.axiosCall({ method: 'get', url: `menus/single-choice-group/?menu=${menuId}` })
  }
  async getSingleChoiceGroup(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/single-choice-group/${id}/` })
  }
  async updateSingleChoiceGroup(id: number, data: Types.ISingleChoiceGroup) {
    return this.axiosCall({ method: 'patch', url: `menus/single-choice-group/${id}/`, data })
  }
  async createSingleChoiceGroup(data: Types.ISingleChoiceGroup) {
    return this.axiosCall({ method: 'post', url: 'menus/single-choice-group/', data })
  }
  async deleteSingleChoiceGroup(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/single-choice-group/${id}/` })
  }
  async cloneSingleChoiceGroup(id: number) {
    return this.axiosCall({ method: 'post', url: `menus/single-choice-group/${id}/clone/` })
  }
  async updateSingleChoiceGroupPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/single-choice-group/change_positions/`, data })
  }

  /** Menu edit - SingleChoiceGroup Entry **/
  async getSingleChoiceEntries(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/single-choice-entry/?group=${id}` })
  }
  async createSingleChoiceEntry(data: Types.IEntry) {
    return this.axiosCall({ method: 'post', url: 'menus/single-choice-entry/', data })
  }
  async updateSingleChoiceEntry(id: number, data: Types.IEntry) {
    return this.axiosCall({ method: 'patch', url: `menus/single-choice-entry/${id}/`, data })
  }
  async deleteSingleChoiceEntry(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/single-choice-entry/${id}/` })
  }
  async updateSingleChoiceEntryPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/single-choice-entry/change_positions/`, data })
  }
  async decreaseSingleChoiceEntriesPrice(id: number) {
    return this.axiosCall({ method: 'post', url: `menus/single-choice-group/${id}/decrease_entries_price/` })
  }
  async increaseSingleChoiceEntriesPrice(id: number) {
    return this.axiosCall({ method: 'post', url: `menus/single-choice-group/${id}/increase_entries_price/` })
  }

  /** Menu edit - MultiChoiceGroup **/
  async getMultiChoiceGroups(menuId: number) {
    return this.axiosCall({ method: 'get', url: `menus/multi-choice-group/?menu=${menuId}` })
  }
  async getMultiChoiceGroup(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/multi-choice-group/${id}/` })
  }
  async createMultiChoiceGroup(data: Types.IMultiChoiceGroup) {
    return this.axiosCall({ method: 'post', url: 'menus/multi-choice-group/', data })
  }
  async updateMultiChoiceGroup(id: number, data: Types.IMultiChoiceGroup) {
    return this.axiosCall({ method: 'patch', url: `menus/multi-choice-group/${id}/`, data })
  }
  async deleteMultiChoiceGroup(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/multi-choice-group/${id}/` })
  }
  async cloneMultiChoiceGroup(id: number) {
    return this.axiosCall({ method: 'post', url: `menus/multi-choice-group/${id}/clone/` })
  }
  async updateMultiChoiceGroupPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/multi-choice-group/change_positions/`, data })
  }

  /** Menu edit - MultiChoiceGroup Entry **/
  async getMultiChoiceEntries(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/multi-choice-entry/?group=${id}` })
  }
  async createMultiChoiceEntry(data: Types.IEntry) {
    return this.axiosCall({ method: 'post', url: 'menus/multi-choice-entry/', data })
  }
  async updateMultiChoiceEntry(id: number, data: Types.IEntry) {
    return this.axiosCall({ method: 'patch', url: `menus/multi-choice-entry/${id}/`, data })
  }
  async deleteMultiChoiceEntry(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/multi-choice-entry/${id}/` })
  }
  async updateMultiChoiceEntryPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/multi-choice-entry/change_positions/`, data })
  }
  async decreaseMultiChoiceEntriesPrice(id: number) {
    return this.axiosCall({ method: 'post', url: `menus/multi-choice-group/${id}/decrease_entries_price/` })
  }
  async increaseMultiChoiceEntriesPrice(id: number) {
    return this.axiosCall({ method: 'post', url: `menus/multi-choice-group/${id}/increase_entries_price/` })
  }

  /** Menu edit - MealGroup **/
  async getMealGroups(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/meal-group/?category__menu=${id}` })
  }
  async getMealGroup(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/meal-group/${id}/` })
  }
  async getMealGroupMeals(mealGroupId: number) {
    return this.axiosCall({ method: 'get', url: `menus/meal/?meal_group=${mealGroupId}` })
  }
  async getMealGroupIngredients(mealGroupId: number) {
    return this.axiosCall({ method: 'get', url: `menus/ingredient/?meal_group=${mealGroupId}` })
  }
  async createMealGroup(data: Types.IMealGroup) {
    return this.axiosCall({ method: 'post', url: 'menus/meal-group/', data })
  }
  async updateMealGroup(id: number, data: Types.IMealGroup) {
    return this.axiosCall({ method: 'patch', url: `menus/meal-group/${id}/`, data })
  }
  async deleteMealGroup(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/meal-group/${id}/` })
  }
  async updateMealGroupsPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/meal-group/change_positions/`, data })
  }

  /** Menu edit - Meal **/
  async getMeals(menuId: number, fields = '') {
    return this.axiosCall({ method: 'get', url: `menus/meal/?size__category__menu=${menuId}&fields=${fields}` })
  }
  async getMeal(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/meal/${id}/` })
  }
  async createMeal(data: Types.IMeal) {
    return this.axiosCall({ method: 'post', url: 'menus/meal/', data })
  }
  async updateMeal(id: number, data: Types.IMeal) {
    return this.axiosCall({ method: 'patch', url: `menus/meal/${id}/`, data })
  }
  async deleteMeal(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/meal/${id}/` })
  }

  /** Menu edit - Ingredient **/
  async getIngredient(id: number) {
    return this.axiosCall({ method: 'get', url: `menus/ingredient/${id}/` })
  }
  async createIngredient(data: Types.IIngredient) {
    return this.axiosCall({ method: 'post', url: 'menus/ingredient/', data })
  }
  async updateIngredient(id: number, data: Types.IIngredient) {
    return this.axiosCall({ method: 'patch', url: `menus/ingredient/${id}/`, data })
  }
  async deleteIngredient(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/ingredient/${id}/` })
  }
  async updateIngredientsPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/ingredient/change_positions/`, data })
  }

  /** Mealset **/
  async getMealSets(filters: any) {
    return this.axiosCall({ method: 'get', url: 'menus/mealset/', params: { ...filters } })
  }
  async createMealset(data: any) {
    return this.axiosCall({ method: 'post', url: 'menus/mealset/', data })
  }
  async updateMealset(id: number, data: any) {
    return this.axiosCall({ method: 'patch', url: `menus/mealset/${id}/`, data })
  }
  async deleteMealset(id: number) {
    return this.axiosCall({ method: 'delete', url: `menus/mealset/${id}/` })
  }
  async updateMealSetsPositions(data: { menu_id: number; postions: Array<{ id: number; position: number }> }) {
    return this.axiosCall({ method: 'post', url: `menus/mealset/change_positions/`, data })
  }

  /** Delivery Areas **/

  async getDeliveryAreas() {
    return this.axiosCall({ method: 'get', url: 'orders/deliveryarea/' })
  }
  async addDeliveryArea(data: Partial<Types.Region>) {
    return this.axiosCall({ method: 'post', url: 'orders/deliveryarea/', data })
  }
  async updateDeliveryArea(deliveryAreaId: number, data: Partial<Types.Region>) {
    return this.axiosCall({ method: 'patch', url: `orders/deliveryarea/${deliveryAreaId}/`, data })
  }
  async deleteDeliveryArea(deliveryAreaId: number) {
    return this.axiosCall({ method: 'delete', url: `orders/deliveryarea/${deliveryAreaId}/` })
  }

  /** Split Bills **/

  async getBills(params: any) {
    return this.axiosCall({ method: 'get', url: `bills/bill/`, params })
  }

  async createBill(data: any) {
    return this.axiosCall({ method: 'post', url: `bills/bill/`, data })
  }

  async deleteBill(id: number) {
    return this.axiosCall({ method: 'delete', url: `bills/bill/${id}/` })
  }

  async updateBill(bill_id: number, data: any) {
    return this.axiosCall({ method: 'patch', url: `bills/bill/${bill_id}/`, data })
  }

  async moveBillPosition(data: any) {
    return this.axiosCall({ method: 'post', url: `bills/bill/move-position/`, data })
  }

  async splitBillEqually(data: any) {
    return this.axiosCall({ method: 'post', url: `bills/bill/split-equally/`, data })
  }

  /** Printings - Bill **/

  async printBill(bill_id: number) {
    return this.axiosCall({ method: 'post', url: 'printing/print-bill/', data: { bill_id } })
  }

  /** Printings - Receipt **/

  async printReceipt(data: any) {
    return this.axiosCall({ method: 'post', url: `printing/print-receipt/`, data })
  }

  /** Printings - Receipt templates **/

  async getReceiptTemplates() {
    return this.axiosCall({ method: 'get', url: `printing/receipt-template/free_receipt_templates/` })
  }
  async createReceiptTemplate(data: any) {
    return this.axiosCall({ method: 'post', url: `printing/receipt-template/`, data })
  }
  async updateReceiptTemplate(id: number, data: any) {
    return this.axiosCall({ method: 'patch', url: `printing/receipt-template/${id}/`, data })
  }
  async deleteReceiptTemplate(id: number, data: any) {
    return this.axiosCall({ method: 'delete', url: `printing/receipt-template/${id}/`, data })
  }
  async assignReceiptTemplate(id: number, data: any) {
    return this.axiosCall({ method: 'patch', url: `printing/receipt-template/${id}/`, data })
  }
  async unassignReceiptTemplate(id: number, data: any) {
    return this.axiosCall({ method: 'patch', url: `printing/receipt-template/${id}/`, data })
  }
  async createNewBaseTemplate(data: any) {
    return this.axiosCall({ method: 'post', url: `printing/receipt-template/create_base_meals_template/`, data })
  }

  /** Printings - Receipt printers **/

  async getReceiptPrinters() {
    return this.axiosCall({ method: 'get', url: `printing/printer/` })
  }
  async createReceiptPrinter(data: any) {
    return this.axiosCall({ method: 'post', url: `printing/printer/`, data })
  }
  async deleteReceiptPrinter(id: number) {
    return this.axiosCall({ method: 'delete', url: `printing/printer/${id}/` })
  }
  async updateReceiptPrinterName(id: number, data: any) {
    return this.axiosCall({ method: 'patch', url: `printing/printer/${id}/`, data })
  }
  async updateReceiptPrinterInternalId(id: number, data: any) {
    return this.axiosCall({ method: 'patch', url: `printing/printer/${id}/`, data })
  }

  /** Printing - Invoices **/

  async getInvoice(id: number | string) {
    return this.axiosCall({ method: 'get', url: `printing/restaurant-invoice/${id}/` })
  }
  async getInvoiceData(params: any) {
    return this.axiosCall({ method: 'get', url: `printing/restaurant-invoice/new_invoice_data/`, params })
  }
  async updateInvoice(id: number | string, data: any) {
    return this.axiosCall({ method: 'patch', url: `printing/restaurant-invoice/${id}/`, data })
  }
  async createInvoice(data: any) {
    return this.axiosCall({ method: 'post', url: `printing/restaurant-invoice/`, data })
  }

  /** Subscriptions **/

  async getCurrentSubscription() {
    return this.axiosCall({ method: 'get', url: 'subscriptions/subscription/current/' })
  }
  async getSubscriptions() {
    return this.axiosCall({ method: 'get', url: 'subscriptions/subscription/' })
  }
  async getSubscriptionsTypes() {
    return this.axiosCall({ method: 'get', url: 'subscriptions/subscription-type/' })
  }
  async getCompany(companyId: number) {
    return this.axiosCall({ method: 'get', url: `subscriptions/company/${companyId}/` })
  }
  async updateCompany(companyId: number, data: Partial<Types.Company>) {
    return this.axiosCall({ method: 'patch', url: `subscriptions/company/${companyId}/`, data })
  }
  async buySubscription(data: any) {
    return this.axiosCall({ method: 'post', baseURL: process.env.VUE_APP_API_URL, url: `payu/buy-subscription/`, data })
  }

  /** Notifications **/
  async getNotifications() {
    return this.axiosCall({ method: 'get', url: 'notifications/notification/' })
  }
  async deleteNotification(pk: number) {
    return this.axiosCall({ method: 'delete', url: `notifications/notification/${pk}/` })
  }

  /** Predictions **/
  async getPredictions(data: Types.GetPredictionsData) {
    return this.axiosCall({ method: 'post', url: 'orders/order/predict_promised_time/', data })
  }

  /** Settings **/
  async savePreferences(data: any) {
    return this.axiosCall({ method: 'patch', url: 'settings/preferences', data })
  }
  async saveErestaurantSettings(data: any) {
    return this.axiosCall({ method: 'patch', url: 'integrations/erestaurant/settings/', data })
  }
  async savePermissions(data: any) {
    return this.axiosCall({ method: 'patch', url: 'settings/permissions', data })
  }

  /** Integrations - Wolt **/
  async integrateWolt(status: string) {
    return this.axiosCall({ method: 'patch', url: 'settings/set-wolt-restaurant-status', data: { status } })
  }
  async getWoltLocalizationStatus() {
    return this.axiosCall({ method: 'get', url: 'settings/wolt-restaurant-status' })
  }

  /** Mapping **/

  /** Mapping - Lists **/

  async getMappingMeals({ params }: { params: Types.IGetMappingListParams }) {
    return this.axiosCall({ method: 'get', url: 'menus/mapping-meal/', params })
  }
  async getMappingSingleChoiceEntries({ params }: { params: Types.IGetMappingListParams }) {
    return this.axiosCall({ method: 'get', url: 'menus/mapping-single-choice-entry/', params })
  }
  async getMappingMultiChoiceEntries({ params }: { params: Types.IGetMappingListParams }) {
    return this.axiosCall({ method: 'get', url: 'menus/mapping-multi-choice-entry/', params })
  }
  async getMappingOrderMeals(order: number) {
    return this.axiosCall({ method: 'get', url: 'orders/mapping-order-meal/', params: { order } })
  }
  async getMappingOrderMealSingleChoiceEntries(order: number) {
    return this.axiosCall({ method: 'get', url: 'orders/mapping-order-meal-single-choice-entry/', params: { order } })
  }
  async getMappingOrderMealMultiChoiceEntries(order: number) {
    return this.axiosCall({ method: 'get', url: 'orders/mapping-order-meal-multi-choice-entry/', params: { order } })
  }

  /** Mapping - Static mapping actions **/

  async mapMeal(mappedId: number, data: Types.IMapMealParams) {
    return this.axiosCall({ method: 'post', url: `menus/meal/${mappedId}/map/`, data })
  }
  async mapSingleChoiceEntry(mappedId: number, data: Types.IMapEntryParams) {
    return this.axiosCall({ method: 'post', url: `menus/single-choice-entry/${mappedId}/map/`, data })
  }
  async mapMultiChoiceEntry(mappedId: number, data: Types.IMapEntryParams) {
    return this.axiosCall({ method: 'post', url: `menus/multi-choice-entry/${mappedId}/map/`, data })
  }
  async removeMappingFromMeal(meal_id: number) {
    return this.axiosCall({ method: 'post', url: `menus/meal/${meal_id}/remove_mapping/` })
  }
  async removeMappingFromSingleChoice(entry_id: number) {
    return this.axiosCall({ method: 'post', url: `menus/single-choice-entry/${entry_id}/remove_mapping/` })
  }
  async removeMappingFromMultiChoice(entry_id: number) {
    return this.axiosCall({ method: 'post', url: `menus/multi-choice-entry/${entry_id}/remove_mapping/` })
  }

  /** Mapping - Dynamic (order) mapping actions **/

  async mapOrderMeal(id: number, data: Types.IMapMealParams) {
    return this.axiosCall({ method: 'post', url: `orders/order-meal/${id}/map/`, data })
  }
  async mapOrderSingleChoiceEntry(id: number, data: Types.IMapEntryParams) {
    return this.axiosCall({
      method: 'post',
      url: `orders/order-meal-single-choice-entry/${id}/map/`,
      data,
    })
  }
  async mapOrderMultiChoiceEntry(id: number, data: Types.IMapEntryParams) {
    return this.axiosCall({
      method: 'post',
      url: `orders/order-meal-multi-choice-entry/${id}/map/`,
      data,
    })
  }

  /** OrderMeal - create element in menu for OrderMeal element **/
  async createMealForOrderMeal(id: number) {
    return this.axiosCall({ method: 'post', url: `orders/order-meal/${id}/create_meal/` })
  }
  async createEntryForOrderMealSingleChoiceEntry(id: number) {
    return this.axiosCall({ method: 'post', url: `orders/order-meal-single-choice-entry/${id}/create_entry/` })
  }
  async createEntryForOrderMealMultiChoiceEntry(id: number) {
    return this.axiosCall({ method: 'post', url: `orders/order-meal-multi-choice-entry/${id}/create_entry/` })
  }

  /** Me */
  async getMe(id: number) {
    return this.axiosCall({ method: 'get', url: `users/me/${id}/` })
  }

  /** Localization */

  async getLocalization(id: number) {
    return this.axiosCall({method: 'get', url: `orders/localization/${id}/`})
  }

  async updateLocalization(id: number, data: any) {
    return this.axiosCall({method: 'patch', url: `orders/localization/${id}/`, data})
  }

  /** KDS - Workstation */

  async getWorkstations() {
    return this.axiosCall({ method: 'get', url: `kds/workstation/` })
  }
  async getWorkstation(id: number) {
    return this.axiosCall({ method: 'get', url: `kds/workstation/${id}/` })
  }
  async createWorkstation(data: Partial<Types.IWorkStation>) {
    return this.axiosCall({ method: 'post', url: `kds/workstation/`, data })
  }
  async updateWorkstation(id: number, data: Partial<Types.IWorkStation>) {
    return this.axiosCall({ method: 'patch', url: `kds/workstation/${id}/`, data })
  }
  async removeWorkstation(id: number) {
    return this.axiosCall({ method: 'delete', url: `kds/workstation/${id}/` })
  }

  /** Recommendations */

  async getRecommendation(localizationId: number) {
    return this.axiosCall({ method: 'get', url: `orders/localization/${localizationId}/recommendation/` })
  }

  // PAPU 3
  async p3_createOrder(data: Partial<Types.IOrderPapu3>) {
    return this.axiosCall({ method: 'post', url: 'reviews/order/', data })
  }
  async p3_getOrders(params: any) {
    return this.axiosCall({ method: 'get', url: `reviews/order/`, params })
  }
  async p3_getOrder(uuid: string) {
    return this.axiosCall({ method: 'get', url: `reviews/order/${uuid}/` })
  }
  async p3_updateOrder(uuid: string, data: Partial<Types.IOrderPapu3>) {
    return this.axiosCall({ method: 'patch', url: `reviews/order/${uuid}/`, data })
  }
  async p3_acceptOrder(uuid: string, data: any) {
    return this.axiosCall({ method: 'patch', url: `reviews/order/${uuid}/accept/`, data })
  }
  async p3_cancelOrder(uuid: string) {
    return this.axiosCall({ method: 'patch', url: `reviews/order/${uuid}/cancel/` })
  }
  async p3_finishOrder(uuid: string, data: any) {
    return this.axiosCall({ method: 'patch', url: `reviews/order/${uuid}/finish/`, data })
  }
  async p3_assignCourierToOrder() {
    return this.axiosCall({ method: 'post', url: `reviews/order/assign_courier_to_orders/` })
  }
  async p3_regeocodeOrder() {
    return this.axiosCall({ method: 'post', url: `reviews/order/regeocode-address/` })
  }
  async p3_transferOrder(uuid: string, localization_id: number) {
    return this.axiosCall({ method: 'patch', url: `reviews/order/${uuid}/transfer/`, data: { localization_id } })
  }
  // END OF PAPU 3
}

export const API = new ApiService()
