<template>
  <div class="auth-page">
    <transition name="slide-down">
      <div v-if="appLoaded" class="auth-logo">
        <img src="/static/images/papuio_logo_white.svg" />
      </div>
    </transition>
    <div class="container">
      <transition name="fade">
        <b-alert
          v-if="appLoaded && loggedOutReason === 'Invalid token'"
          show
          variant="danger"
          class="auth-element text-center"
        >
          {{ $t('message.auth.loggedOutInvalidToken') }}
        </b-alert>
      </transition>
      <transition name="fade">
        <b-alert v-if="appLoaded && errorMessage" show variant="danger" class="auth-element text-center">
          {{ errorMessage }}
        </b-alert>
      </transition>
      <transition name="fade">
        <b-alert v-if="appLoaded && responseMessage" show variant="success" class="auth-element text-center">
          <div class="h3">{{ $t('message.demo.thankYou') }}</div>
          <span>{{ responseMessage }} <a href="https://papu.io/blog/">https://papu.io/blog/</a></span>
        </b-alert>
      </transition>
      <transition name="fade">
        <div v-if="appLoaded && !responseMessage" class="card auth-card p-4">
          <b-form ref="signInForm" @submit.prevent="createDemo()">
            <b-row>
              <b-col order="2" cols="12" sm="6">
                <h3 class="mb-4">{{ $t('message.demo.createAccount') }}</h3>
              </b-col>
            </b-row>
            <div class="row">
              <div class="col-12 col-md-6">
                <h5>{{ $t('message.demo.company') }}</h5>

                <b-form-group>
                  <label for="company_email"
                    >{{ $t('message.demo.company_email') }}<span class="text-danger ml-1">*</span></label
                  >
                  <b-form-input
                    id="company_email"
                    v-model="formInputs.company.company_email"
                    type="email"
                    class="form-control"
                    name="company_email"
                    required
                  >
                  </b-form-input>
                  <small v-if="validationErrors.company.company_email" class="form-text text-danger">
                    {{ validationErrors.company.company_email }}
                  </small>
                </b-form-group>

                <b-form-group>
                  <label for="country">{{ $t('message.demo.country') }}<span class="text-danger ml-1">*</span></label>
                  <b-form-select v-model="formInputs.localization.country" :options="countryOptions"></b-form-select>
                  <small v-if="validationErrors.localization.country" class="form-text text-danger">
                    {{ validationErrors.localization.country }}
                  </small>
                </b-form-group>

                <b-form-group>
                  <label for="logo">{{ $t('message.demo.logo') }}<span class="text-danger ml-1">*</span></label>
                  <b-form-file accept="image/*" @change="handleImage" />
                  <small v-if="validationErrors.company.logo" class="form-text text-danger">
                    {{ validationErrors.company.logo }}
                  </small>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox
                    id="ecommerce"
                    v-model="formInputs.company.ecommerce"
                    name="ecommerce"
                    class="d-flex align-items-center"
                  >
                    Ecommerce
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6">
                <h5>{{ $t('message.demo.localization') }}</h5>
                <b-form-group>
                  <label for="name">{{ $t('message.demo.name') }}<span class="text-danger ml-1">*</span></label>
                  <b-form-input
                    id="name"
                    v-model="formInputs.localization.name"
                    type="text"
                    class="form-control"
                    name="name"
                    required
                    max-length="36"
                  >
                  </b-form-input>
                  <small v-if="validationErrors.localization.name" class="form-text text-danger">
                    {{ validationErrors.localization.name }}
                  </small>
                </b-form-group>

                <b-form-group>
                  <label for="city">{{ $t('message.demo.city') }}<span class="text-danger ml-1">*</span></label>
                  <b-form-input
                    id="city"
                    v-model="formInputs.localization.city"
                    type="text"
                    class="form-control"
                    name="city"
                    required
                  >
                  </b-form-input>
                  <small v-if="validationErrors.localization.city" class="form-text text-danger">
                    {{ validationErrors.localization.city }}
                  </small>
                </b-form-group>

                <div class="row">
                  <div class="col-8">
                    <b-form-group>
                      <label for="street">{{ $t('message.demo.street') }}<span class="text-danger ml-1">*</span></label>
                      <b-form-input
                        id="street"
                        v-model="formInputs.localization.street"
                        type="text"
                        class="form-control"
                        name="street"
                        required
                      >
                      </b-form-input>
                      <small v-if="validationErrors.localization.street" class="form-text text-danger">
                        {{ validationErrors.localization.street }}
                      </small>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group>
                      <label for="house">{{ $t('message.demo.house') }}<span class="text-danger ml-1">*</span></label>
                      <b-form-input
                        id="house"
                        v-model="formInputs.localization.house"
                        type="text"
                        class="form-control"
                        name="house"
                        required
                      >
                      </b-form-input>
                      <small v-if="validationErrors.localization.house" class="form-text text-danger">
                        {{ validationErrors.localization.house }}
                      </small>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group>
                  <label for="phone">{{ $t('message.demo.phone') }}<span class="text-danger ml-1">*</span></label>
                  <b-form-input
                    id="phone"
                    v-model="formInputs.localization.localization_phone"
                    type="text"
                    class="form-control"
                    name="phone"
                    :state="validationErrors.localization.localization_phone !== '' ? false : null"
                  >
                  </b-form-input>
                  <small v-if="validationErrors.localization.localization_phone" class="form-text text-danger">
                    {{ validationErrors.localization.localization_phone }}
                  </small>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-checkbox
                  id="phone-calls"
                  v-model="phoneCalls"
                  name="phone-calls"
                  class="d-flex align-items-center"
                >
                  <p class="mt-1">{{ $t('message.demo.phoneCalls') }}<span class="text-danger ml-1">*</span></p>
                </b-form-checkbox>
              </div>
            </div>

            <b-button
              v-shortkey="['enter']"
              variant="success"
              block
              size="lg"
              :disabled="formIsEmpty || submitButtonDisable"
              class="auth-btn text-uppercase"
              @click="createDemo()"
              @shortkey="createDemo()"
              >{{ $t('message.demo.createAccount') }}
            </b-button>
          </b-form>
          <div class="small text-muted mt-3" v-html="$t('message.demo.fineprint')"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateDemo',
  props: ['loggedOutReason'],
  data: function () {
    return {
      formInputs: {
        company: {
          company_email: '',
          logo: null,
          ecommerce: true,
        },
        localization: {
          name: '',
          street: '',
          city: '',
          house: '',
          localization_phone: '',
          country: '',
        },
      },
      validationFields: [
        'localization.country',
        'company.company_email',
        'company.logo',
        'localization.name',
        'localization.city',
        'localization.house',
        'localization.localization_phone',
        'localization.street',
      ],
      validationErrors: {
        company: {
          company_email: '',
          logo: '',
        },
        localization: {
          name: '',
          street: '',
          city: '',
          house: '',
          localization_phone: '',
          country: '',
        },
      },
      submitButtonDisable: false,
      responseMessage: '',
      errorMessage: '',
      appLoaded: false,
      phoneCalls: false,
      countryOptions: [
        { value: 'PL', text: this.$t('message.demo.pl') },
        { value: 'DE', text: this.$t('message.demo.de') },
        // { value: 'UK', text: this.$t('message.demo.uk') },
      ],
    }
  },
  computed: {
    formIsEmpty() {
      return (
        this.appLoaded &&
        (this.formInputs.localization.country.length === 0 ||
          this.formInputs.company.company_email.length === 0 ||
          this.formInputs.localization.localization_phone.length === 0 ||
          this.formInputs.localization.name.length === 0 ||
          this.formInputs.localization.city.length === 0 ||
          this.formInputs.localization.house.length === 0 ||
          this.formInputs.localization.street.length === 0 ||
          this.phoneCalls === false)
      )
    },
  },
  mounted() {
    // initiate facebook tracking code (js code in script of pixel.js)
    if (process.env.VUE_APP_DEMO_FACEBOOK_PIXEL_ID) {
      const facebookPixelDemoScript = document.createElement('script')
      facebookPixelDemoScript.setAttribute('src', 'pixel.js')
      facebookPixelDemoScript.setAttribute('demo-facebook-pixel-id', process.env.VUE_APP_DEMO_FACEBOOK_PIXEL_ID)
      document.head.appendChild(facebookPixelDemoScript)
      const facebookPixelDemoNoScript = document.createElement('noscript')
      const imgNoScript = document.createElement('img')
      imgNoScript.setAttribute('height', '1')
      imgNoScript.setAttribute('width', '1')
      imgNoScript.setAttribute('style', 'display:none')
      imgNoScript.setAttribute(
        'src',
        `https://www.facebook.com/tr?id=${process.env.VUE_APP_DEMO_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`
      )
      facebookPixelDemoNoScript.appendChild(imgNoScript)
      document.head.appendChild(facebookPixelDemoNoScript)
    }

    setTimeout(() => {
      this.appLoaded = true
    }, 300)
  },
  methods: {
    validateForm(errorData) {
      if (errorData['company_email'] !== undefined) {
        this.validationErrors.company.company_email = errorData['company_email'][0]
      }
      if (errorData['name'] !== undefined) {
        this.validationErrors.company.company_email = errorData['name'][0]
      }
      if (errorData['city'] !== undefined) {
        this.validationErrors.company.company_email = errorData['city'][0]
      }
      if (errorData['house'] !== undefined) {
        this.validationErrors.company.company_email = errorData['house'][0]
      }
      if (errorData['street'] !== undefined) {
        this.validationErrors.company.company_email = errorData['street'][0]
      }
    },
    cleanForm() {
      this.formInputs.localization.country =
        this.formInputs.localization.localization_phone =
        this.formInputs.company.company_email =
        this.formInputs.localization.name =
        this.formInputs.localization.street =
        this.formInputs.localization.city =
        this.formInputs.localization.house =
          ''
      this.formInputs.company.logo = null
    },
    cleanErrors() {
      this.validationErrors.localization.country =
        this.validationErrors.localization.localization_phone =
        this.validationErrors.company.company_email =
        this.validationErrors.localization.name =
        this.validationErrors.localization.street =
        this.validationErrors.localization.city =
        this.validationErrors.localization.house =
        this.validationErrors.company.logo =
          ''
    },
    handleImage(e) {
      this.createBase64Image(e.target.files[0])
    },
    createBase64Image(imageObject) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.formInputs.company.logo = e?.target?.result
      }
      reader.readAsDataURL(imageObject)
    },
    createDemo() {
      this.submitButtonDisable = true
      this.errorMessage = ''
      this.cleanErrors()
      this.$store.dispatch('auth/createDemo', {
        data: this.formInputs,
        onSuccess: () => {
          this.cleanForm()
          this.responseMessage = this.$t('message.demo.successMessage')
        },
        onError: (errorResponse) => {
          if (errorResponse.status !== 400) this.errorMessage = this.$t('message.demo.errorMessage')
          this.validateForm(errorResponse.data)
          this.submitButtonDisable = false
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.auth-page {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  background-image: url('/static/images/auth-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-logo {
  margin-bottom: 50px;
  img {
    width: 220px;
  }
}
.auth-btn {
  margin-top: unset;
}

.fade-leave-active {
  transition: none;
}

@include media-breakpoint-down(md) {
  .auth-page {
    justify-content: flex-start;
  }
  .auth-logo {
    margin-top: 30px;
  }
}
@include media-breakpoint-up(md) {
  .auth-logo {
    margin-bottom: 30px;
    img {
      width: 320px;
    }
  }
}
</style>
