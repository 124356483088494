<template>
  <b-modal
    :id="'choices' + multiChoiceGroup.id"
    ref="modal"
    dialog-class="modal-fullscreen-dialog"
    content-class="modal-fullscreen-content"
    body-class="p-0"
    scrollable
    tabindex="-1"
    @hide="hide()"
    @show="show()"
    @shown="shown()"
  >
    <template #modal-header>
      <div class="modal-title mr-2">
        {{ $t('message.multiChoiceEntryModal.additives') }}
      </div>
      <div class="input-group">
        <input
          ref="multiChoiceGroupInput"
          v-model="searchKeyword"
          type="text"
          class="form-control input-lg"
          :placeholder="$t('message.multiChoiceEntryModal.search')"
          @input="searchEntry"
        />
      </div>

      <button id="save-multi-choice-entry-btn" type="button" class="btn btn-success save-btn" @click="hide()">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark small">esc</kbd> {{ $t('message.multiChoiceEntryModal.save') }}
      </button>
    </template>
    <IngredientsList
      variant="selected"
      :title="$t('message.multiChoiceEntryModal.selectedAdditives')"
      :empty-list-message="$t('message.multiChoiceEntryModal.noAdditives')"
      :closeable="true"
      :items="multiChoiceGroupEntries"
      :button-class="'pp-button--primary'"
      @onIngredientClick="
        (item) => {
          $emit('removeMultiChoiceGroupEntry', item)
        }
      "
    />
    <div class="ingredients-sections-divider" />
    <IngredientsList
      :title="$t('message.multiChoiceEntryModal.paidAdditives')"
      :empty-list-message="$t('message.multiChoiceEntryModal.noAdditivesToChoose')"
      :items="getEntriesArray()"
      :button-class="'ingredient'"
      @onIngredientClick="
        (item) => {
          $emit('addMultiChoiceEntry', item)
        }
      "
    />
    <IngredientsList
      :title="$t('message.multiChoiceEntryModal.freeAdditives')"
      :empty-list-message="$t('message.multiChoiceEntryModal.noIngredientsToChoose')"
      :items="getEntriesArray()"
      :button-class="'ingredient'"
      :no-price="true"
      @onIngredientClick="
        (item) => {
          $emit('addMultiChoiceEntry', {
            id: item.id,
            group_name: multiChoiceGroup.name,
            name: item.name,
            price: 0,
            uuid: item.uuid,
          })
        }
      "
    />
    <template #modal-footer class="p-0">
      <button
        id="save-multi-choice-entry-btn--footer"
        type="button"
        class="btn btn-success btn-lg btn-block"
        @click="hide()"
      >
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark small">esc</kbd> {{ $t('message.multiChoiceEntryModal.save') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import IngredientsList from './IngredientsList'
/* global $, */
export default {
  name: 'MultiChoiceEntryModal',
  components: { IngredientsList },
  props: ['multiChoiceGroup', 'meal', 'multiChoiceGroupEntries'],
  data: function () {
    return {
      searchKeyword: '',
      searchResults: this.multiChoiceGroup,
    }
  },
  computed: {
    ...mapGetters(['currency']),
  },
  updated() {
    // Focusing on input after every child click -- fix for not loosing focus on Modal after removing mcg/ingredient
    document.addEventListener('click', this.focusOnInput)
  },
  methods: {
    hide() {
      this.$bvModal.hide('choices' + this.multiChoiceGroup.id)
      this.$emit('onModalToggle', false)
    },
    show() {
      this.$emit('onModalToggle', true)
    },
    shown() {
      // Focusing on input on every Modal show
      this.focusOnInput()
    },
    searchEntry() {
      this.searchResults = []
      if (this.searchKeyword !== '') {
        const words = this.searchKeyword.split(' ')
        if (words.length > 0 && words[0].length > 0) {
          this.multiChoiceGroup.entries.forEach((entry) => {
            let include = true
            words.forEach((word) => {
              if (!entry.name.toLowerCase().includes(word.toLowerCase())) {
                include = false
              }
            })
            if (include) {
              this.searchResults.push(entry)
            }
          })
        }
      }
    },
    getEntriesArray() {
      if (!this.searchKeyword) {
        return this.multiChoiceGroup.entries
      } else {
        return this.searchResults
      }
    },
    focusOnInput() {
      if (this.$refs.multiChoiceGroupInput) this.$refs.multiChoiceGroupInput.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.ingredients-sections-divider {
  border-bottom: 2px solid #bbbbbb;
  width: 100%;
}
.save-btn {
  width: 200px;
  margin-left: 15px;
}
</style>
