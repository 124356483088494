<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="h3 mb-0">
        {{ $t('message.menu.categories.title') }}
        <b-badge v-if="!loadingList" variant="primary" pill class="ml-2" style="vertical-align: middle">
          {{ categories.length }}
        </b-badge>
      </h3>
      <b-button variant="primary" size="sm" :disabled="saving" @click="addNewCategory">
        <i class="fas fa-plus-square mr-2" aria-hidden="true" /> {{ $t('message.menu.categories.add') }}
      </b-button>
    </div>
    <b-overlay :show="loadingList" spinner-variant="primary" spinner-small style="min-height: 300px">
      <draggable
        v-model="categories"
        v-bind="dragOptions"
        class="list-group custom-list-group"
        @change="updateCategoriesPositions"
      >
        <categories-list-element
          v-for="category in categories"
          :key="category.id"
          :category="category"
          :is-active="selectedCategoryId === category.id"
          @onClick="editCategory"
        />
      </draggable>
      <template v-if="!loadingList && categories.length === 0">
        <p class="text-center">
          {{ $t(`message.menu.categories.noCategories`) }}
        </p>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import { mapFields } from 'vuex-map-fields'
import CategoriesListElement from './CategoriesListElement'

const STORE = '_menuEdit'

export default {
  name: 'CategoriesList',
  components: { CategoriesListElement, draggable },
  data() {
    return {
      loadingList: false,
      selectedCategoryId: null,
    }
  },
  computed: {
    ...mapGetters(STORE, ['isEditMode', 'saving', 'getActiveMenuId']),
    ...mapGetters(['isMobile']),
    ...mapFields(STORE, ['categories']),
    dragOptions() {
      return {
        animation: 200,
        group: 'categories',
        disabled: false,
        ghostClass: 'ghost',
        handle: this.isMobile ? '.drag-icon' : '.custom-list-group-item',
      }
    },
  },
  watch: {
    isEditMode(value) {
      if (!value) {
        this.selectedCategoryId = false
      }
    },
  },
  mounted() {
    this.refreshList()
  },
  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited', 'setCategories']),
    ...mapActions(STORE, ['getCategories', 'getCategory', 'addCategory', 'updateElementsPositions']),
    async refreshList() {
      this.loadingList = true
      await this.getCategories()
      this.loadingList = false
    },
    async editCategory(category) {
      this.selectedCategoryId = category.id
      await this.getCategory(category.id)
    },
    addNewCategory() {
      this.selectedCategoryId = null
      this.addCategory()
    },
    async updateCategoriesPositions() {
      let requestData = this.categories.map((category, index) => ({ id: category.id, position: index + 1 }))
      requestData = { menu_id: this.getActiveMenuId, positions: requestData }
      const { error } = await this.updateElementsPositions({ requestData, apiCall: 'updateCategoriesPositions' })
      if (error) {
        await this.getCategories()
      }
    },
  },
}
</script>
