<template>
  <b-modal
    id="confirmLeaveModal"
    ref="confirmLeaveModal"
    size="md"
    :title="modalTitle"
    centered
    :hide-header-close="!closingOption"
    hide-footer
    :hide-header="!closingOption"
    :no-close-on-esc="!closingOption"
    :no-close-on-backdrop="!closingOption"
    @hidden="hidden"
  >
    <b-container fluid class="text-center">
      <b-row>
        <b-col>
          <b-button class="confirmButton w-100" variant="secondary" size="sm" @click="noPublish(true)">
            {{ $t('message.common.cancel') }}
          </b-button>
        </b-col>
        <b-col>
          <b-overlay :show="saving" rounded="sm" spinner-small>
            <b-button class="confirmButton w-100" variant="success" size="sm" @click="publish">
              {{ $t('message.menu.publish') }}
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'ConfirmLeaveAndSaveModal',
  props: ['to', 'nextMenuCache'],
  data() {
    return {
      closingOption: true,
      modalTitle: this.nextMenuCache
        ? this.$t('message.menu.publishModalMenuChangeText')
        : this.$t('message.menu.publishModalText'),
    }
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
  },
  methods: {
    init() {
      this.closingOption = true
      this.modalTitle = this.$t('message.menu.publishModalText')
    },
    noPublish() {
      this.$emit('noPublish')
    },
    hidden() {
      this.init()
    },
    publish() {
      this.$emit('publish', { source: 'fromModal' })
    },
  },
}
</script>

<style scoped>
.confirmButton {
  min-width: 10em;
  min-height: 4em;
}
</style>
