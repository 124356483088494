<template>
  <form-placeholder v-if="loaders.form" />
  <b-card v-else-if="form" class="h-100" no-body>
    <b-alert
      :show="
        !saving &&
        errors &&
        errors.items.some((error) =>
          ['noEntries', 'noSelectedMeals', 'sizePrice', 'noMealSizes', 'noPrices', 'mealNameExists'].includes(
            error.field
          )
        )
      "
      variant="danger"
    >
      <multi-error-message
        :fields="['noEntries', 'noSelectedMeals', 'sizePrice', 'noMealSizes', 'noPrices', 'mealNameExists']"
        :current-errors="errors"
      />
    </b-alert>
    <b-card-header header-class="header">
      <b-card-title title-tag="h3" class="h5 mb-0 mr-2">
        {{ getTitle }}
      </b-card-title>
      <div class="d-flex">
        <b-button variant="outline-secondary" :disabled="saving" size="sm" class="mr-2 px-lg-3" @click="cancel">
          {{ $t('message.common.cancel').toUpperCase() }}
        </b-button>
        <b-overlay :show="saving" rounded="sm" spinner-small>
          <b-button v-if="isNewItemMode" variant="success" size="sm" class="px-lg-3" @click="create">
            <i class="fas fa-save mr-2" aria-hidden="true" />
            {{ $t('message.common.save').toUpperCase() }}
          </b-button>
          <b-button
            v-else
            variant="danger"
            :disabled="saving || defaultSize || defaultCategory"
            size="sm"
            class="px-lg-3"
            @click="remove"
          >
            <i class="fas fa-trash-alt mr-2" aria-hidden="true" />
            {{ $t('message.common.delete').toUpperCase() }}
          </b-button>
        </b-overlay>
      </div>
    </b-card-header>
    <b-card-body body-class="py-3 overflow-y-auto">
      <template>
        <category-edit v-if="menuElement === 'categories'" ref="categoryEdit" @editFinished="initEditElements" />
        <size-edit v-if="menuElement === 'sizes'" ref="sizeEdit" @editFinished="initEditElements" />
        <choice-group-edit
          v-if="menuElement === 'multi_choice_groups' || menuElement === 'single_choice_groups'"
          ref="choiceGroupEdit"
          @editFinished="initEditElements"
          @update="updateChoiceGroup"
        />
        <meal-edit
          v-if="menuElement === 'meals'"
          ref="mealEdit"
          @editFinished="initEditElements"
          @update="updateMealGroup"
        />
      </template>
    </b-card-body>
  </b-card>
  <div v-else class="no-edit-item-info d-flex flex-column align-items-center justify-content-center h-100">
    <div class="font-weight-medium">{{ $t('message.menu.edit.noEditElementTitle') }}</div>
    <div class="text-black-50">{{ $t('message.menu.edit.noEditElementBody') }}</div>
  </div>
</template>

<script>
import CategoryEdit from './edit/CategoryEdit'
import SizeEdit from './edit/SizeEdit'
import ChoiceGroupEdit from './edit/ChoiceGroupEdit'
import MealEdit from './edit/MealEdit'
import FormPlaceholder from './edit/generic/FormPlaceholder'
import MultiErrorMessage from '@/components/MultiErrorMessage'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { showSuccessToast } from '@/utils/common'

const STORE = '_menuEdit'

export default {
  name: 'Edit',
  components: {
    CategoryEdit,
    SizeEdit,
    ChoiceGroupEdit,
    MealEdit,
    MultiErrorMessage,
    FormPlaceholder,
  },
  provide() {
    return {
      $validator: this.$validator,
    }
  },
  data() {
    return {
      state: null,
      states: [
        { state: 'updated', text: this.$t('message.menu.states.saved') },
        { state: 'created', text: this.$t('message.menu.states.added') },
        { state: 'deleted', text: this.$t('message.menu.states.deleted') },
      ],
    }
  },
  computed: {
    ...mapGetters(STORE, [
      'form',
      'loaders',
      'isSingleChoiceGroup',
      'isMultiChoiceGroup',
      'isEditMode',
      'isNewItemMode',
      'getActiveMenuId',
      'saving',
      'menuElement',
      'mealGroups',
    ]),
    defaultSize() {
      return this.menuElement === 'sizes' && this.form?.is_default
    },
    defaultCategory() {
      return this.menuElement === 'categories' && this.form?.is_default
    },
    getTitle() {
      const mode = this.isEditMode ? 'edit' : 'new'
      return this.$t(`message.menu.${mode}.${this.menuElement}`)
    },
    getItemAPIData() {
      let [requestData, ref, apiCreateMethod, apiDeleteMethod, apiUpdateMethod] = Array(5).fill(null)

      switch (this.menuElement) {
        case 'categories':
          {
            requestData = this.getRequestData('category', this.form, {
              menu: this.getActiveMenuId,
            })
            ref = 'categoryEdit'
            apiCreateMethod = 'createCategory'
            apiDeleteMethod = 'deleteCategory'
          }
          break
        case 'sizes':
          {
            requestData = this.getRequestData('size', this.form, {
              category: this.form.category.id,
            })
            ref = 'sizeEdit'
            apiCreateMethod = 'createSize'
            apiDeleteMethod = 'deleteSize'
          }
          break
        case 'multi_choice_groups':
        case 'single_choice_groups':
          {
            requestData = this.getRequestData('choice-group', this.form, {
              menu: this.getActiveMenuId,
            })
            ref = 'choiceGroupEdit'
            apiCreateMethod = this.isSingleChoiceGroup ? 'createSingleChoiceGroup' : 'createMultiChoiceGroup'
            apiDeleteMethod = this.isSingleChoiceGroup ? 'deleteSingleChoiceGroup' : 'deleteMultiChoiceGroup'
            apiUpdateMethod = this.isSingleChoiceGroup ? 'updateSingleChoiceGroup' : 'updateMultiChoiceGroup'
          }
          break
        case 'meals':
          {
            requestData = this.getRequestData('meal', this.form, {
              image: this.form.imageBase64,
            })
            ref = 'mealEdit'
            apiCreateMethod = 'createMealGroup'
            apiDeleteMethod = 'deleteMealGroup'
            apiUpdateMethod = 'updateMealGroup'
          }
          break
      }

      return { requestData, ref, apiCreateMethod, apiDeleteMethod, apiUpdateMethod }
    },
  },
  watch: {
    form() {
      this.clear()
    },
  },
  destroyed() {
    this.clear()
  },
  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited', 'clearForm']),
    ...mapActions(STORE, [
      'createElement',
      'deleteElement',
      'updateElement',
      'refreshElementsList',
      'getSingleChoiceGroups',
      'getMultiChoiceGroups',
      'getMealGroups',
    ]),
    clear() {
      this.errors.items = []
    },
    initEditElements(state = null) {
      this.clearForm()
      if (state)
        this.state = this.states.find((s) => {
          return s.state === state
        }).text
      else this.state = null
      this.$emit('edit-mode', false)
    },
    cancel() {
      this.initEditElements()
    },
    remove() {
      this.$bvModal
        .msgBoxConfirm(this.$t('message.menu.deleteMsgBox.confirm'), {
          centered: true,
          size: 'sm',
          okTitle: this.$t('message.common.delete'),
          cancelTitle: this.$t('message.common.cancel'),
          okVariant: 'danger',
          cancelVariant: 'outline-secondary',
          footerClass: 'modal-footer--menu-management',
        })
        .then(async (confirm) => {
          if (!confirm) return false
          const { apiDeleteMethod } = this.getItemAPIData
          if (this.form) {
            const { error } = await this.deleteElement({ id: this.form.id, apiCall: apiDeleteMethod })
            if (!error) {
              this.initEditElements('deleted')
              showSuccessToast(this.$toasted, this.state)
              await this.refreshElementsList(this.menuElement)
            }
          }
        })
    },
    async create() {
      const { requestData, ref, apiCreateMethod } = this.getItemAPIData
      await this.$refs[ref].$validator.validate().then(async (result) => {
        if (this.menuElement === 'single_choice_groups' || this.menuElement === 'multi_choice_groups') {
          result = this.validateChoiceGroup(result)
        } else if (this.menuElement === 'meals') {
          result = this.validateMeals(result)
        }
        if (result) {
          const { error } = await this.createElement({ requestData, apiCall: apiCreateMethod })
          if (!error) {
            this.initEditElements('created')
            showSuccessToast(this.$toasted, this.state)
            await this.refreshElementsList(this.menuElement)
          }
        }
      })
    },
    async updateChoiceGroup(requestData) {
      const { ref } = this.getItemAPIData
      const apiUpdateMethod = this.isSingleChoiceGroup ? 'updateSingleChoiceGroup' : 'updateMultiChoiceGroup'
      await this.$refs[ref].$validator.validate().then(async (result) => {
        result = this.validateChoiceGroup(result)
        if (result && this.isEditMode) {
          const { error } = await this.updateElement({ id: this.form.id, requestData, apiCall: apiUpdateMethod })
          if (!error) {
            this.isSingleChoiceGroup ? await this.getSingleChoiceGroups() : await this.getMultiChoiceGroups()
          }
        }
      })
    },
    async updateMealGroup(requestData) {
      const { ref, apiUpdateMethod } = this.getItemAPIData
      await this.$refs[ref].$validator.validate().then(async (result) => {
        result = this.validateMeals(result, requestData)
        if (result && this.isEditMode) {
          const { error } = await this.updateElement({ id: this.form.id, requestData, apiCall: apiUpdateMethod })
          if (!error) {
            await this.getMealGroups()
          }
        }
      })
    },
    validateChoiceGroup(result) {
      if (this.form.id && this.form.entries.length === 0) {
        this.errors.add({
          field: 'noEntries',
          msg: this.$t('message.menu.choiceGroups.noEntries'),
        })
        result = false
      } else {
        this.errors.items = this.errors.items.filter((item) => {
          return item.field !== 'noEntries'
        })
      }
      let anyMealsSelected = this.form.meals.length > 0
      if (!anyMealsSelected) {
        this.errors.add({
          field: 'noSelectedMeals',
          msg: this.$t('message.menu.choiceGroups.noSelectedMeals'),
        })
        result = false
      } else {
        this.errors.items = this.errors.items.filter((item) => {
          return item.field !== 'noSelectedMeals'
        })
      }
      return result
    },
    validateMeals(result, requestData) {
      if (!this.form.meals || this.form.meals.length === 0) {
        this.errors.add({
          field: 'noMealSizes',
          msg: this.$t('message.menu.meal.noMealSizes'),
        })
        result = false
      } else {
        this.errors.items = this.errors.items.filter((item) => {
          return item.field !== 'noMealSizes'
        })
      }
      // Check if name is not duplicated (if there's no meal of same name in category)
      let mealNameExists = this.mealGroups.some(
        (mealGroup) => mealGroup.category === this.form.category && mealGroup.name === this.form.name
      )
      if (mealNameExists && requestData?.name) {
        this.errors.add({
          field: 'mealNameExists',
          msg: this.$t('message.menu.meal.mealNameExists'),
        })
        result = false
      } else
        this.errors.items = this.errors.items.filter((item) => {
          return item.field !== 'mealNameExists'
        })
      // Check meals prices
      let hasFilledPrices = this.form.meals?.every((meal) => meal.price !== null && meal.price !== '')
      if (!hasFilledPrices) {
        this.errors.add({
          field: 'noPrices',
          msg: this.$t('message.menu.meal.noPrices'),
        })
        result = false
      } else
        this.errors.items = this.errors.items.filter((item) => {
          return item.field !== 'noPrices'
        })
      return result
    },
    getRequestData(itemType, item, extraData) {
      const fields = {
        category: ['name', 'is_default', 'vat', 'in_erestaurant'],
        size: ['name', 'is_default', 'in_erestaurant'],
        'choice-group': ['name', 'in_erestaurant', 'meals'],
        meal: ['name', 'description', 'in_erestaurant', 'meals', 'category'],
      }
      const data = Object.assign({}, ...fields[itemType].map((el) => ({ [el]: item[el] })))
      return { ...data, ...extraData }
    },
  },
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
</style>
