import { CURRENCY } from '@/common/constants'
import { RootState } from './index'
import { isMobile as isMobileUtils } from '@/utils/common'
import { IMenuExtended, ICategory, ISize, IMeal } from '@/types/interface'

const menus = (state: RootState) => state.menus
const menu = (state: RootState) =>
  state.menus.find((menu: IMenuExtended) => menu.brand === state.currentBrandId && menu.source === state.currentSource)
    ?.json || null
const menuIndex = (state: RootState) =>
  state.menus.findIndex(
    (menu: IMenuExtended) => menu.brand === state.currentBrandId && menu.source === state.currentSource
  )

const getMenuRequestStatus = (state: RootState) => state.getMenuRequestStatus
const isMenuLoading = (state: RootState) => state.getMenuRequestStatus === 'loading'
const categories = (state: RootState, getters: any) => getters.menu?.categories || []
const multiChoiceGroups = (state: RootState, getters: any) => getters.menu?.multi_choice_groups || []
const singleChoiceGroups = (state: RootState, getters: any) => getters.menu?.single_choice_groups || []
const currentSource = (state: RootState, getters: any) => getters.menu.source

const currentBrand = (state: RootState) => state.auth?.brands?.[state.currentBrandId || 0]
const currentBrandId = (state: RootState) => state.currentBrandId
const currentBrandName = (state: RootState) => state.currentBrandName
const notifications = (state: RootState) => state.notifications
const stateLoadedFromSession = (state: RootState) => state.stateLoadedFromSession
const newOrdersCount = (state: RootState) => state.newOrdersCount
const halfPizzaSettings = (state: RootState) => state.halfPizzaSettings
const currency = (state: RootState) => {
  const companyCurrency = state.auth?.company?.currency
  const currencies: any = CURRENCY
  const currencySign = companyCurrency ? currencies[companyCurrency] : null
  return currencySign || companyCurrency
}
const delayOrdersListRefresh = (state: RootState) => state.delayOrdersListRefresh
const isMobile = () => isMobileUtils()
const getPredictionsRequest = (state: RootState) => state.getPredictionsRequest

const getChoiceGroupIndexes =
  (state: RootState, getters: any) =>
  ({ category, size, meals }: any) => {
    const categoryIndex = getters.categories.findIndex((cat: ICategory) => cat.id === category.id)
    const sizeIndex = getters.categories[categoryIndex].sizes.findIndex((el: ISize) => el.id === size.id)
    const mealIndexes = getters.categories[categoryIndex].sizes[sizeIndex].meals
      .map((meal: IMeal, index: boolean) => (meals.includes(meal.id) ? index : null))
      .filter((el: any) => el !== null)
    return { categoryIndex, sizeIndex, mealIndexes }
  }

export default {
  menu,
  menus,
  menuIndex,
  categories,
  multiChoiceGroups,
  singleChoiceGroups,
  currentSource,
  currentBrand,
  currentBrandId,
  currentBrandName,
  notifications,
  stateLoadedFromSession,
  newOrdersCount,
  halfPizzaSettings,
  currency,
  delayOrdersListRefresh,
  isMobile,
  getChoiceGroupIndexes,
  getMenuRequestStatus,
  isMenuLoading,
  getPredictionsRequest,
}
