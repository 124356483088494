<template>
  <b-container class="overflow-y-auto settings-view">
    <div ref="widget" class="widget">
      <b-overlay :show="loading" class="h-100" no-wrap />
      <div ref="widgetBodyWrapper" class="widget-body-wrapper p-0">
        <b-row>
          <b-col>
            <b-nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-white px-3 pt-3">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'settings' }" tag="a">{{ $t('message.settings.settings') }}</router-link>
                </li>
                <li class="breadcrumb-item d-flex align-items-center">
                  <router-link :to="{ name: 'erestaurant' }" tag="a">
                    {{ $t('message.erestaurant.erestaurant') }}
                  </router-link>
                </li>
                <li class="breadcrumb-item d-flex align-items-center">
                  <template v-if="Object.values(erestoBrands).length === 1">
                    <span v-if="actualBrand">{{ actualBrand.name }}</span>
                  </template>
                  <template v-else>
                    <b-button variant="primary" size="sm" @click="openBrandsModal()"
                      >{{ actualBrand.name || $t('choose_brand') }} <i class="fas fa-chevron-down ml-2"
                    /></b-button>
                  </template>
                </li>
              </ol>
            </b-nav>
          </b-col>
        </b-row>
        <template v-if="showSettings">
          <div id="eresto-config" class="justify-content-center">
            <div class="administration-section">
              <i class="fas fa-store pr-3 pt-1"></i>{{ $t('message.erestaurant.message') }} {{ eresto.domain }}
            </div>
            <b-row class="administration-section-content">
              <b-col cols="12" md="9">
                <b-table-simple bordered class="table--cell-wrap">
                  <b-thead>
                    <tr>
                      <b-th></b-th>
                      <b-th class="cell__file-input">{{ $t('message.erestaurant.link') }}</b-th>
                      <b-th class="cell__preview">{{ $t('message.erestaurant.preview') }}</b-th>
                    </tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr ref="logo">
                      <b-td>{{ $t('message.erestaurant.logo') }} <small>(min. 200x200 px)</small></b-td>
                      <b-td>
                        <b-form-file
                          v-model="eresto.brand.logo"
                          :placeholder="$t('message.erestaurant.loadFile')"
                          :browse-text="$t('message.erestaurant.choose')"
                          accept="image/jpeg, image/png, image/gif"
                          @input="runPreview(eresto.brand.logo, 'logoUrl')"
                        />
                      </b-td>
                      <b-td>
                        <b-img v-if="logoUrl" width="100" height="100" thumbnail :src="logoUrl" />
                      </b-td>
                    </b-tr>
                    <b-tr v-if="responseErrors && responseErrors.logo">
                      <b-td colspan="3" class="response-error">
                        <response-error-msg field="logo" :response-errors="responseErrors" />
                      </b-td>
                    </b-tr>

                    <b-tr ref="background">
                      <b-td>{{ $t('message.erestaurant.background') }} <small>(1536x200px)</small></b-td>
                      <b-td>
                        <b-form-file
                          v-model="eresto.brand.background"
                          :placeholder="$t('message.erestaurant.loadFile')"
                          :browse-text="$t('message.erestaurant.choose')"
                          accept="image/jpeg, image/png, image/gif"
                          @input="runPreview(eresto.brand.background, 'backgroundUrl')"
                        />
                      </b-td>
                      <b-td>
                        <b-img v-if="backgroundUrl" width="100" thumbnail :src="backgroundUrl" />
                      </b-td>
                    </b-tr>
                    <b-tr v-if="responseErrors && responseErrors.background">
                      <b-td colspan="3" class="response-error">
                        <response-error-msg field="background" :response-errors="responseErrors" />
                      </b-td>
                    </b-tr>

                    <b-tr ref="favicon">
                      <b-td>{{ $t('message.erestaurant.icon') }} <small>(32x32px)</small></b-td>
                      <b-td>
                        <b-form-file
                          v-model="eresto.favicon"
                          :placeholder="$t('message.erestaurant.loadFile')"
                          :browse-text="$t('message.erestaurant.choose')"
                          accept="image/jpeg, image/png, image/gif"
                          @input="runPreview(eresto.favicon, 'faviconUrl')"
                        />
                      </b-td>
                      <b-td>
                        <b-img v-if="faviconUrl" width="30" height="30" thumbnail :src="faviconUrl" />
                      </b-td>
                    </b-tr>
                    <b-tr v-if="responseErrors && responseErrors.favicon">
                      <b-td colspan="3" class="response-error">
                        <response-error-msg field="favicon" :response-errors="responseErrors" />
                      </b-td>
                    </b-tr>

                    <b-tr ref="brand.eresto_different_prices">
                      <b-td colspan="2">
                        {{ $t('message.erestaurant.differentPrices', { restaurant: actualBrand.name }) }}
                      </b-td>
                      <b-td class="text-center">
                        <label class="switch">
                          <input v-model="eresto.brand.eresto_different_prices" type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="responseErrors && responseErrors.brand && responseErrors.brand.eresto_different_prices">
                      <b-td colspan="3" class="response-error">
                        <response-error-msg field="brand.eresto_different_prices" :response-errors="responseErrors" />
                      </b-td>
                    </b-tr>

                    <b-tr ref="brand.eresto_complex_meal_half_pizza_available">
                      <b-td colspan="2">{{ $t('message.erestaurant.complexMealsAvailable') }}</b-td>
                      <b-td class="text-center">
                        <label class="switch">
                          <input v-model="eresto.brand.eresto_complex_meal_half_pizza_available" type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </b-td>
                    </b-tr>
                    <b-tr
                      v-if="
                        responseErrors &&
                        responseErrors.brand &&
                        responseErrors.brand.eresto_complex_meal_half_pizza_available
                      "
                    >
                      <b-td colspan="3" class="response-error">
                        <response-error-msg
                          field="brand.eresto_complex_meal_half_pizza_available"
                          :response-errors="responseErrors"
                        />
                      </b-td>
                    </b-tr>

                    <b-tr ref="brand.eresto_show_restaurant_email">
                      <b-td colspan="2">{{ $t('message.erestaurant.showRestaurantEmail') }}</b-td>
                      <b-td class="text-center">
                        <label class="switch">
                          <input v-model="eresto.brand.eresto_show_restaurant_email" type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </b-td>
                    </b-tr>
                    <b-tr
                      v-if="responseErrors && responseErrors.brand && responseErrors.brand.eresto_show_restaurant_email"
                    >
                      <b-td colspan="3" class="response-error">
                        <response-error-msg
                          field="brand.eresto_show_restaurant_email"
                          :response-errors="responseErrors"
                        />
                      </b-td>
                    </b-tr>

                    <b-tr ref="brand.eresto_send_order_confirmation_email_copy_to_bcc">
                      <b-td colspan="2">{{ $t('message.erestaurant.sendOrderConfirmationEmailToBCC') }}</b-td>
                      <b-td class="text-center">
                        <label class="switch">
                          <input
                            v-model="eresto.brand.eresto_send_order_confirmation_email_copy_to_bcc"
                            type="checkbox"
                          />
                          <span class="slider round"></span>
                        </label>
                      </b-td>
                    </b-tr>
                    <b-tr
                      v-if="
                        responseErrors &&
                        responseErrors.brand &&
                        responseErrors.brand.eresto_send_order_confirmation_email_copy_to_bcc
                      "
                    >
                      <b-td colspan="3" class="response-error">
                        <response-error-msg
                          field="brand.eresto_send_order_confirmation_email_copy_to_bcc"
                          :response-errors="responseErrors"
                        />
                      </b-td>
                    </b-tr>

                    <b-tr ref="brand.active" :variant="eresto.active ? 'success' : 'danger'">
                      <b-td colspan="2">
                        <div class="label-with-tooltip">
                          {{ $t('message.erestaurant.active') }}
                          <TooltipButton id="erestaurant_active" button-class="defaultTooltipButton">
                            ?
                            <template #tooltipText>{{ $t('message.erestaurant.activeTooltip') }}</template>
                          </TooltipButton>
                        </div>
                      </b-td>
                      <b-td class="text-center">
                        <label class="switch">
                          <input v-model="eresto.active" type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="responseErrors && responseErrors.eresto && responseErrors.eresto.active">
                      <b-td colspan="3" class="response-error">
                        <response-error-msg field="eresto.active" :response-errors="responseErrors" />
                      </b-td>
                    </b-tr>

                    <b-tr ref="brand.eresto_active" :variant="eresto.brand.eresto_active ? 'success' : 'danger'">
                      <b-td colspan="2">
                        <div class="label-with-tooltip">
                          {{ $t('message.erestaurant.brandActive', { restaurant: actualBrand.name }) }}
                          <TooltipButton id="erestaurant_brand_active" button-class="defaultTooltipButton">
                            ?
                            <template #tooltipText>
                              {{ $t('message.erestaurant.brandActiveTooltip', { restaurant: actualBrand.name }) }}
                            </template>
                          </TooltipButton>
                        </div>
                      </b-td>
                      <b-td class="text-center">
                        <label class="switch">
                          <input v-model="eresto.brand.eresto_active" type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="responseErrors && responseErrors.brand && responseErrors.brand.eresto_active">
                      <b-td colspan="3" class="response-error">
                        <response-error-msg field="brand.eresto_active" :response-errors="responseErrors" />
                      </b-td>
                    </b-tr>

                    <b-tr ref="brand.eresto_show_restaurant_email">
                      <b-td colspan="2">{{ $t('message.erestaurant.erestoDisableDelivery') }}</b-td>
                      <b-td class="text-center">
                        <label class="switch">
                          <input v-model="eresto.brand.eresto_disable_delivery" type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="responseErrors && responseErrors.brand && responseErrors.brand.eresto_disable_delivery">
                      <b-td colspan="3" class="response-error">
                        <response-error-msg
                          field="brand.eresto_show_restaurant_email"
                          :response-errors="responseErrors"
                        />
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

                <div>
                  <div class="label-with-tooltip mt-3 mb-2">{{ $t('message.erestaurant.title') }}</div>
                  
                  <b-form-input id="eresto-site-title" ref="name" v-model="eresto.name" />
                  
                  <response-error-msg field="name" :response-errors="responseErrors" />
                </div>

                <div>
                  <div class="label-with-tooltip mt-3 mb-2">
                    <span>{{ $t('message.erestaurant.description') }}</span>
                    <TooltipButton id="eresto_site_description_tooltip" button-class="defaultTooltipButton">
                      ?
                      <template #tooltipText>{{ $t('message.erestaurant.descriptionTooltip') }}</template>
                    </TooltipButton>
                  </div>
                  
                  <b-form-textarea
                    id="eresto-site-description"
                    ref="description"
                    v-model="eresto.description"
                    max="155"
                  />

                  <response-error-msg field="description" :response-errors="responseErrors" />
                </div>

                <div class="mb-4">
                  <div class="label-with-tooltip mt-3 mb-2">
                    <span>{{ $t('message.erestaurant.keywords') }}</span>
                    <TooltipButton id="eresto_site_keywords_tooltip" button-class="defaultTooltipButton">
                      ?
                      <template #tooltipText>{{ $t('message.erestaurant.keywordsTooltip') }}</template>
                    </TooltipButton>
                  </div>
                  
                  <b-form-input id="eresto-site-keywords" ref="keywords" v-model="eresto.keywords" />
                  
                  <response-error-msg field="keywords" :response-errors="responseErrors" />
                </div>

                <div class="mb-4">
                  <div class="label-with-tooltip mt-3 mb-2">
                    <span>{{ $t('erestaurant_banner_url') }}</span>
                    <TooltipButton id="erestaurant_banner_url_tooltip" button-class="defaultTooltipButton">
                      ?
                      <template #tooltipText>{{ $t('erestaurant_banner_url_tooltip') }}</template>
                    </TooltipButton>
                  </div>

                  <b-form-input id="eresto-banner-link" ref="eresto_banner_url" v-model="eresto.brand.eresto_banner_url" />
                  
                  <response-error-msg field="brand.eresto_banner_url" :response-errors="responseErrors" />
                </div>
              </b-col>
            </b-row>
          </div>

          <div id="order-time-limits" class="justify-content-center">
            <div class="administration-section">
              <i class="fa fa-clock pr-3 pt-1"></i
              >{{ $t('message.erestaurant.ordersTimeLimit.message', { restaurant: actualBrand.name }) }}
            </div>
            <b-row class="administration-section-content mt-4">
              <b-col cols="12" md="9">
                <div class="orderTimeLimitsRowHead">
                  {{ $t('message.erestaurant.ordersTimeLimit.minTime') }}
                </div>

                <div class="mt-2 mb-4">
                  <b-table
                    :items="orderTimeLimitsMinimumOptions"
                    :fields="orderTimeLimitsFields"
                    thead-class="d-none"
                    class="mb-0 table--cell-wrap"
                    bordered
                  >
                    <template #cell(preference)="data">
                      <b-input-group
                        v-if="data.item.preference"
                        :ref="`brand.${data.item.preference}`"
                        :append="$t('message.erestaurant.minutes')"
                        style="width: 200px"
                      >
                        <b-form-input
                          v-model="eresto.brand[data.item.preference]"
                          size="lg"
                          type="number"
                          number
                        ></b-form-input>
                      </b-input-group>
                    </template>
                  </b-table>
                  <div
                    v-if="responseErrors && responseErrors.brand && orderTimeLimitsMinimumOptions"
                    class="response-error"
                  >
                    <template v-for="(option, optionIndex) in orderTimeLimitsMinimumOptions">
                      <response-error-msg
                        v-if="responseErrors.brand[option.preference]"
                        :key="`preference_error_${optionIndex}`"
                        :field="`brand.${responseErrors.brand[option.preference]}`"
                        :response-errors="responseErrors"
                      />
                    </template>
                  </div>
                </div>

                <div class="orderTimeLimitsRowHead">
                  {{ $t('message.erestaurant.ordersTimeLimit.maxTime') }}
                </div>
                <b-table
                  :items="orderTimeLimitsMaximumOptions"
                  :fields="orderTimeLimitsFields"
                  thead-class="d-none"
                  bordered
                  class="mt-2 mb-4 table--cell-wrap"
                >
                  <template #cell(preference)="data">
                    <b-input-group
                      v-if="data.item.preference"
                      :ref="`brand.${data.item.preference}`"
                      :append="$t('message.erestaurant.minutes')"
                      style="width: 200px"
                    >
                      <b-form-input
                        v-model="eresto.brand[data.item.preference]"
                        size="lg"
                        type="number"
                        number
                      ></b-form-input>
                    </b-input-group>
                  </template>
                </b-table>
                <div
                  v-if="responseErrors && responseErrors.brand && orderTimeLimitsMaximumOptions"
                  class="response-error"
                >
                  <template v-for="(option, optionIndex) in orderTimeLimitsMaximumOptions">
                    <response-error-msg
                      v-if="responseErrors.brand[option.preference]"
                      :key="`preference_error_${optionIndex}`"
                      :field="`brand.${responseErrors.brand[option.preference]}`"
                      :response-errors="responseErrors"
                    />
                  </template>
                </div>
              </b-col>
            </b-row>
          </div>

          <div
            v-if="eresto.brand.eresto_opening_hours"
            id="orders-summary-opening-hours"
            ref="brand.eresto_opening_hours"
            class="justify-content-center mb-3"
          >
            <div class="administration-section">
              <i class="fa fa-clock pr-3 pt-1"></i
              >{{ $t('message.erestaurant.openingHours', { restaurant: actualBrand.name }) }}
            </div>
            <b-row class="administration-section-content mt-4">
              <b-col cols="12" md="9">
                <week-time-table
                  ref="opening_hours_week_time_table"
                  v-model="eresto.brand.eresto_opening_hours"
                  table-name="opening_hours"
                  @validationErrors="setValidationErrors"
                ></week-time-table>
                <response-error-msg field="brand.eresto_opening_hours" :response-errors="responseErrors" />
              </b-col>
            </b-row>
          </div>

          <div id="orders-summary-analytics" class="justify-content-center">
            <div class="administration-section">
              <i class="far fa-chart-bar pr-3 pt-1"></i>{{ $t('message.erestaurant.analytics') }}
            </div>

            <b-row class="administration-section-content my-4">
              <b-col cols="12" md="9">

                <div>
                  <div class="label-with-tooltip mb-2">
                    Google Analytics ID:
                    <TooltipButton id="erestaurant_google_analytics_id" button-class="defaultTooltipButton">
                      ?
                      <template #tooltipText>{{ $t('message.erestaurant.googleAnalyticsInfo') }}</template>
                    </TooltipButton>
                  </div>

                  <b-form-input
                    id="google-analytics-id-input"
                    ref="google_analytics_id"
                    v-model="eresto.google_analytics_id"
                  />

                  <response-error-msg field="google_analytics_id" :response-errors="responseErrors" class="mb-3"/>
                </div>

                
                <div>
                  <div class="label-with-tooltip mt-3 mb-2">
                    Google Tag Manager ID:
                    <TooltipButton id="google_tag_manager_id" button-class="defaultTooltipButton">
                      ?
                      <template #tooltipText>{{ $t('message.erestaurant.googleTagManagerInfo') }}</template>
                    </TooltipButton>
                  </div>

                  <b-form-input
                    id="google-tag-manager-id-input"
                    ref="google_tag_manager_id"
                    v-model="eresto.google_tag_manager_id"
                  />

                  <response-error-msg field="google_tag_manager_id" :response-errors="responseErrors" class="mb-3"/>
                </div>

                <div>
                  <div class="label-with-tooltip mt-3 mb-2">
                    {{ $t('message.erestaurant.facebookPixelId') }}
                    <TooltipButton id="erestaurant_facebook_pixel_id" button-class="defaultTooltipButton">
                      ?
                      <template #tooltipText>{{ $t('message.erestaurant.facebookPixelIdInfo') }}</template>
                    </TooltipButton>
                  </div>

                  <b-form-input
                    id="facebook-pixel-id-input"
                    ref="facebook_pixel_id"
                    v-model="eresto.facebook_pixel_id"
                  />

                  <response-error-msg field="facebook_pixel_id" :response-errors="responseErrors" class="mb-3"/>
                </div>

                <div>
                  <div class="label-with-tooltip mt-3 mb-2">
                    {{ $t('message.erestaurant.facebookPixelMetaTag') }}
                    <TooltipButton id="erestaurant_facebook_pixel_metatag" button-class="defaultTooltipButton">
                      ?
                      <template #tooltipText>{{ $t('message.erestaurant.facebookPixelMetaTagInfo') }}</template>
                    </TooltipButton>
                  </div>
                  
                  <b-form-input
                    id="facebook-pixel-input"
                    ref="facebook_pixel_metatag"
                    v-model="eresto.facebook_pixel_metatag"
                  />
                  
                  <response-error-msg field="facebook_pixel_metatag" :response-errors="responseErrors" class="mb-3"/>
                </div>
              </b-col>
            </b-row>
          </div>
        </template>
        <b-row v-else class="py-5 position-relative">
          <Loader />
        </b-row>
      </div>
      <div v-if="showSettings" class="widget-footer-container">
        <b-col cols="9" class="widget-footer widget-footer--without-shadow border-0">
          <b-button block variant="success" @click="validateAndSave()">
            {{ $t('message.administration.saveChanges') }}
          </b-button>
        </b-col>
      </div>
    </div>
  </b-container>
</template>

<script>
import WeekTimeTable from '@/components/WeekTimeTable'
import { settingsMixins, responseErrorsHandler } from '@/mixins'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import TooltipButton from '@/components/TooltipButton'
import Loader from '@/components/Loader'

export default {
  name: 'Erestaurant',
  components: {
    TooltipButton,
    WeekTimeTable,
    Loader,
  },
  mixins: [settingsMixins, responseErrorsHandler],
  data() {
    return {
      eresto: {
        name: '',
        description: '',
        keywords: '',
        active: false,
        favicon: null,
        brand: {
          logo: null,
          background: null,
          eresto_banner_url: null,
          google_analytics_id: '',
          google_tag_manager_id: '',
          facebook_pixel_id: '',
          facebook_pixel_metatag: '',
          eresto_active: false,
          eresto_complex_meal_half_pizza_available: true,
          eresto_show_restaurant_email: false,
          eresto_send_order_confirmation_email_copy_to_bcc: false,
          eresto_different_prices: false,
          eresto_pickup_order_min_time: 0,
          eresto_delivery_order_min_time: 0,
          eresto_pickup_order_max_time: 0,
          eresto_delivery_order_max_time: 0,
          eresto_opening_hours: {},
        },
        brands: [],
      },
      showSettings: false,
      actualBrand: {
        name: '',
      },
      orderTimeLimitsMinimumOptions: [
        { text: this.$t('message.erestaurant.ordersTimeLimit.pickup'), preference: 'eresto_pickup_order_min_time' },
        { text: this.$t('message.erestaurant.ordersTimeLimit.delivery'), preference: 'eresto_delivery_order_min_time' },
      ],
      orderTimeLimitsMaximumOptions: [
        { text: this.$t('message.erestaurant.ordersTimeLimit.pickup'), preference: 'eresto_pickup_order_max_time' },
        { text: this.$t('message.erestaurant.ordersTimeLimit.delivery'), preference: 'eresto_delivery_order_max_time' },
      ],
      orderTimeLimitsFields: ['text', 'preference'],
      loading: false,
      faviconUrl: '',
      backgroundUrl: '',
      logoUrl: '',
    }
  },
  computed: {
    ...mapGetters('settings', ['erestoPreferences']),
    ...mapGetters({
      erestoBrands: 'auth/erestoBrands',
    }),
  },
  mounted() {
    if (Object.values(this.erestoBrands).length > 1) {
      this.openBrandsModal()
      this.$eventBus.$on('brandChosen', () => {
        this.getEresto()
      })
    } else {
      this.getEresto()
    }
  },
  methods: {
    ...mapActions(['chooseMenu']),
    ...mapMutations('settings', ['setErestoPreferences']),
    openBrandsModal() {
      this.$eventBus.$emit('onShowBrandsModal', { erestoBrands: true })
    },
    setValidationErrors(value) {
      this.validationErrors[value.tableName] = value.errors
    },
    validate() {
      if (this.$refs.opening_hours_week_time_table) this.$refs.opening_hours_week_time_table.validateInputs()
    },
    save() {
      const toSave = { ...this.eresto }
      toSave.brand = { ...this.eresto.brand }
      if (toSave.favicon) {
        toSave.favicon = this.faviconUrl
      } else {
        delete toSave.favicon
      }
      if (toSave.brand.logo) {
        toSave.brand.logo = this.logoUrl
      } else {
        delete toSave.brand.logo
      }
      if (toSave.brand.background) {
        toSave.brand.background = this.backgroundUrl
      } else {
        delete toSave.brand.background
      }

      delete toSave.brands

      this.loading = true
      this.clearResponseErrors()
      this.savePreferences('erestaurant', {
        formData: toSave,
        onSuccess: (responseData) => {
          this.setErestoPreferences(responseData)
          sessionStorage.setItem('erestoPreferences', JSON.stringify(responseData))
          this.loading = false
        },
        onError: (err) => {
          this.setResponseErrors(err.response.data)

          const messages = this.responseErrors
          const status = err?.response?.status || null
          let firstErrorKey = messages ? Object.keys(messages)[0] : null

          if (messages[firstErrorKey]?.constructor === Object && messages[firstErrorKey] !== null) {
            firstErrorKey = `${firstErrorKey}.${Object.keys(messages[firstErrorKey])[0]}`
          }

          if (status !== 500 && messages) {
            this.setResponseErrors(messages)
            this.scrollToFieldWithError(firstErrorKey)
          } else {
            this.$toasted.show(messages, {
              type: 'error',
              duration: 5000,
            })
          }
          this.loading = false
        },
      })
    },
    chooseBrand(brandName, brandId) {
      sessionStorage.setItem('currentBrandName', brandName)
      sessionStorage.setItem('currentBrandId', brandId)
      this.chooseMenu({ brandId: parseInt(brandId), brandName })
      this.getEresto()
    },
    getEresto() {
      this.showSettings = false
      this.backgroundUrl = null
      this.logoUrl = null
      this.faviconUrl = null
      let eresto = null
      let brand = null
      if (Object.values(this.erestoBrands).length > 1) {
        let currentBrandId = sessionStorage.getItem('currentBrandId')
        this.actualBrand = JSON.parse(sessionStorage.getItem('brands'))[currentBrandId]
        eresto = Object.values(this.erestoPreferences).find((prefs) => {
          return prefs.uuid === this.actualBrand.eresto
        })
        brand = eresto.brands.find((brand) => brand.id == currentBrandId)
      } else {
        let brandId = Object.entries(this.erestoBrands)[0][0]
        this.actualBrand = JSON.parse(sessionStorage.getItem('brands'))[brandId]
        eresto = this.erestoPreferences[0]
        brand = eresto.brands[0]
      }
      eresto = { ...this.eresto, ...eresto }
      eresto.brand = { ...brand }
      this.formatImagesFiles(eresto)
      this.eresto = eresto
      setTimeout(() => {
        this.showSettings = true
      }, 20)
    },
    scrollToFieldWithError(fieldName) {
      const { offsetTop: wrapperOffsetTop } = this.$refs.widget
      const wrapperChildWithScroll = this.$refs.widgetBodyWrapper
      const field = this.$refs[fieldName]

      const { top: fieldDistanceFromViewport } = field.$el.getBoundingClientRect()
      const { scrollTop } = wrapperChildWithScroll

      wrapperChildWithScroll.scrollTo({
        top: scrollTop + (fieldDistanceFromViewport - wrapperOffsetTop),
        left: 0,
        behavior: 'smooth',
      })
    },
    formatImagesFiles(eresto) {
      if (typeof eresto.favicon === 'string') {
        this.faviconUrl = eresto.favicon
        eresto.favicon = null
      }
      if (typeof eresto.brand.background === 'string') {
        this.backgroundUrl = eresto.brand.background
        eresto.brand.background = null
      }
      if (typeof eresto.brand.logo === 'string') {
        this.logoUrl = eresto.brand.logo
        eresto.brand.logo = null
      }

      return eresto
    },
    runPreview(file, previewField) {
      if (file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this[previewField] = reader.result
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.orderTimeLimitsRowHead {
  font-weight: bold;
}

.response-error {
  border: 0;
  font-size: 80%;
  font-weight: 400;
  padding-top: 0;
}
</style>
