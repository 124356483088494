import { mapGetters } from 'vuex'

export const newOrderMixins = {
  computed: {
    ...mapGetters({
      isMultiBrand: 'auth/isMultiBrand',
    }),
  },
  methods: {
    showBrandsModal(payload = {}) {
      this.$eventBus.$emit('onShowBrandsModal', payload)
    },
    goToNewOrder(onsite = false) {
      // If meal was changed, it pops up confirmation modal, otherwise changes view
      if (this.$store.state['_orderEdit'] && !this.$store.getters['_orderEdit/mealSaved']) {
        this.$eventBus.$emit('onShowMealDiscardChangesModal')
      } else {
        this.$router.push({ name: 'new-order', params: { edit: false }, query: { orderOnSite: onsite } })
        // If it's not new action and _orderEdit is already registered (as if you're actually in orderEdit),
        // start MealsMenu view for new order
        if (this.$store.state['_orderEdit']) {
          this.$eventBus.$emit('initNewOrder')
          this.$eventBus.$emit('onUpdateCartActiveMeal', null)
        }
      }
    },
    onClickTrigger(onsite = false) {
      this.isMultiBrand ? this.showBrandsModal({ orderOnSite: onsite, showOnline: false }) : this.goToNewOrder(onsite)
      this.$emit('trigger-clicked')
    },
  },
}
