<template>
  <div class="widget">
    <div class="widget-body-wrapper">
      <b-row>
        <b-col cols="12" md="6" class="mx-auto">
          <b-card
            class="mt-md-5"
            border-variant="dark-blue"
            :header="$t('message.navMain.help')"
            header-bg-variant="dark-blue"
            header-text-variant="white"
            align="center"
          >
            <b-card-text>
              <HelpData class="list--divided" />
            </b-card-text>
          </b-card>
          <ItemChat with-label class="chat" />

          <b-button variant="primary" block @click="reportBug()">
            <i class="fas fa-bug mr-2" />
            {{ $t('report_sugestion') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import HelpData from '@/components/navigation/HelpData'
import ItemChat from '@/components/navigation/ItemChat'

export default {
  name: 'Help',
  components: {
    HelpData,
    ItemChat,
  },
  methods: {
    reportBug() {
      if (window.Ybug) {
        window.Ybug.open()
      }
    },
  },
}
</script>
