<template>
  <div>
    <name-size v-model="name" @blur="updateSize({ name })" />
    <is-default-switch
      v-model="is_default"
      :disabled="is_default"
      :tooltip-text="$t('message.menu.sizes.defaultTooltipText')"
      @click="updateSize({ is_default })"
    >
      <template #label>
        {{ $t('message.menu.sizes.defaultSize') }}
      </template>
    </is-default-switch>
    <hr />
    <eresto-size v-if="isErestoBrand" v-model="in_erestaurant" @click="updateSize({ in_erestaurant })" />
    <hr />
    <div class="edit-section">
      <b-button variant="primary" size="sm" class="px-3" :disabled="saving" @click="openMergeSizesModal">
        <i class="fas fa-link mr-2" aria-hidden="true" />
        {{ $t('message.menu.sizes.mergeSizes') }}
      </b-button>
    </div>
    <edit-merge-sizes-modal />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import EditMergeSizesModal from '../modals/EditMergeSizesModal'
import NameSize from './size/NameSize'
import IsDefaultSwitch from './generic/IsDefaultSwitch'
import ErestoSize from './size/ErestoSize'

const STORE = '_menuEdit'

export default {
  name: 'SizeEdit',
  components: {
    EditMergeSizesModal,
    NameSize,
    IsDefaultSwitch,
    ErestoSize,
  },
  computed: {
    ...mapGetters(STORE, ['isEditMode', 'saving', 'menuElement']),
    ...mapGetters({ isErestoBrand: 'auth/isErestoBrand' }),
    ...mapFields(STORE, [
      'form.name',
      'form.is_default',
      'form.meals',
      'form.in_erestaurant',
      'form.categoryIndex',
      'form.id',
    ]),
  },
  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited']),
    ...mapActions(STORE, ['updateElement', 'getSizes']),
    openMergeSizesModal() {
      this.$bvModal.show('mergeSizesModal')
    },
    async updateSize(requestData) {
      if (this.isEditMode) {
        const { error } = await this.updateElement({ id: this.id, requestData, apiCall: 'updateSize' })
        if (!error) {
          await this.getSizes()
        }
      }
    },
  },
}
</script>
