<template>
  <div class="layout">
    <template v-if="stateLoadedFromSession">
      <NavMain v-if="isLoggedIn() && showMainMenu"></NavMain>
      <div
        :class="['layout-content', { 'mt-0 p-0': !showMainMenu, 'layout-content--bottom-spacing': isNavBottomDisplay }]"
      >
        <Notifications v-if="isLoggedIn()" />
        <router-view :key="cacheComponents ? null : $route.path" />
      </div>
      <brands-modal v-if="isLoggedIn()" :closable="closableBrandsModal" />
      <order-papu3-modal v-if="isLoggedIn() && $hasModuleAccess(MODULES.papu_3)" />
      <NavBottom v-if="isNavBottomDisplay" />
    </template>
    <details-modal v-if="!$hasModuleAccess(MODULES.papu_3)" />
    <details-modal-papu-3 v-if="$hasModuleAccess(MODULES.papu_3)" />
    <div v-if="!isAuthView && getMenuRequestStatus === 'loading'" class="app-menu-loader">
      <span aria-hidden="true" class="spinner-border text-primary"></span
      ><span>{{ $t('message.auth.loadingMenu') }}</span>
    </div>
  </div>
</template>


<script>
import NavMain from '@/components/navigation/NavMain'
import NavBottom from '@/components/navigation/NavBottom'
import { mapGetters } from 'vuex'
import Notifications from '@/components/Notifications'
import BrandsModal from '@/components/BrandsModal'
import DetailsModal from '@/views/order-details/_components/DetailsModal'
import DetailsModalPapu3 from '@/views/papu-3/order-details/_components/DetailsModal'
import OrderPapu3Modal from '@/components/papu-3/order-form/OrderPapu3Modal'

export default {
  name: 'DefaultLayout',
  components: {
    NavMain,
    NavBottom,
    Notifications,
    BrandsModal,
    DetailsModal,
    DetailsModalPapu3,
    OrderPapu3Modal,
  },
  computed: {
    ...mapGetters({
      stateLoadedFromSession: 'stateLoadedFromSession',
      getMenuRequestStatus: 'getMenuRequestStatus',
    }),
    isAuthView() {
      return this.$route.name === 'auth'
    },
    showMainMenu() {
      const meta = this.$route.meta
      let show = true
      if (Object.keys(meta).length !== 0 && meta.hasOwnProperty('withMainMenu')) {
        show = meta.withMainMenu
      }
      return show
    },
    cacheComponents() {
      const meta = this.$route.meta
      let cache = false
      if (Object.keys(meta).length !== 0 && meta.hasOwnProperty('cache')) {
        cache = meta.cache
      }
      return cache
    },
    isNavBottomDisplay() {
      return ['orders-list', 'couriers-map', 'settings', 'help'].includes(this.$route.name)
    },
    closableBrandsModal() {
      return !['mealsets-list', 'erestaurant'].includes(this.$route.name)
    },
  },
}
</script>
